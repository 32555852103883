import { As, Box, Button, ButtonGroup, ButtonProps, Container, useColorModeValue, BoxProps, Divider, Textarea ,Flex, Heading, useColorModeValue as mode, HStack, Icon, IconButton, IconButtonProps, Input, Stack, Text, useEditableControls, VStack, StackDivider, InputGroup, InputLeftElement, useBreakpointValue, Badge, Table, Tbody, Td, Th, Thead, Tr, ListItem, List, Square, UnorderedList, EditableInput, Editable, EditablePreview, ListIcon, useEditableState, SimpleGrid, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, Tag, TagLeftIcon, DrawerCloseButton, Portal } from '@chakra-ui/react'
import * as React from 'react'
import { useState } from 'react';
import { FiEdit2, FiMail, FiCheck, FiX, FiSquare } from 'react-icons/fi';
import API from '../../../../api/API';
import { IApiBauKGProtocol } from '../../../../api/models/IApiBauKGProtocol';
import { RadioIconButtonGroup, RadioIconButton } from '../../../cui-pro/RadioIconButtonGroup/RadioIconButtonGroup';
import { ShellContentContainer } from '../../../cui-pro/ShellWithSidebar/ShellContentContainer';
import { StepsWithAccent } from '../../../cui-pro/StepsWithAccent/StepsWithAccent';
import { Step } from '../../../cui-pro/StepsWithCircles/Step';
import { useStep } from '../../../cui-pro/StepsWithCircles/useStep';
import { StepsWithCirclesAndText } from '../../../cui-pro/StepsWithCirclesAndText/StepsWithCirclesAndText';
import { EditProtocolChecklist } from './EditProtocolChecklist';
import { EditProtocolGeneral } from './EditProtocolGeneral';
import { EditProtocolDeficits } from './EditProtocolDeficits';
import { IEditProtocolDrawerProps } from './IEditProtocolDrawer';
import { EditProtocolSummary } from './EditProtocolSummary';

export const EditProtocolDrawer = (props: IEditProtocolDrawerProps ) => {

    const numberOfSteps = 4;

    const [protocol, setProtocol] = useState(props.protocol);
    const [currentStep, { setStep }] = useStep({ maxStep: numberOfSteps })

    React.useEffect(() => {
        setProtocol(props.protocol);
        //setStep(0);
    }, [props.protocol])

    
    const isOpen = protocol != undefined;

    if(!isOpen) {
        return <></>;
    }

    var checklistStatistics = [0, 0, 0];
    Object.getOwnPropertyNames(protocol.checklist).forEach(topic => {
        Object.getOwnPropertyNames(protocol.checklist[topic]).forEach(entry => {
            switch(protocol.checklist[topic][entry]) {
                case false: { checklistStatistics[0]++; break; }
                case true:  { checklistStatistics[1]++; break; }
                case null:  { checklistStatistics[2]++; break; }
            }
        });
    });

    const setProtocolData = (property: string, value: any) => {
        API.setProtocolData(props.project.id, protocol.id, property, value).then(result => console.log(result));
    }

    return (
        <Portal>
        <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={() => props.onClose(protocol)}
            size='lg'
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    Protokoll [{protocol.id}]
                    <IconButton aria-label='close' icon={<FiX />} 
                                variant="ghost" p={'0'} m={'0'} h="30px" w="30px" 
                                onClick={() => props.onClose(protocol)} 
                            />
                </DrawerHeader>
                <Divider />
                <DrawerBody py={'8px'} px={'0'} style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                    <StepsWithAccent currentStep={currentStep} setStep={setStep} steps={[
                      {
                        title: 'Allgemein',
                        description: (protocol.date && protocol.time) ? `${protocol.date?.substring(5).replace("-", ".")}. | ${protocol.time}` : "-",
                      },
                      {
                        title: 'Checkliste',
                        description: `${checklistStatistics[0]} / ${checklistStatistics[1]} / ${checklistStatistics[2]}`,
                      },
                      {
                        title: 'Mängel',
                        description: protocol.deficits.length.toString(),
                      },
                      {
                        title: 'Übersicht',
                        //description: `${props.project.id} / ${protocol.id}` ,
                        description: "------------"
                      }                     
                    ]} />
                    <Divider />
                    
                    {
                        [
                            <EditProtocolGeneral protocol={protocol}
                                                  setDate={(date) => {
                                                    var value = (typeof date === 'function') ? date(protocol.date) : date;
                                                    setProtocol(oldProtocol => ({ ...oldProtocol!, date: value }));
                                                    setProtocolData("date", value);
                                                  }}
                                                  setTime={(time) => {
                                                    var value = (typeof time === 'function') ? time(protocol.time) : time;
                                                    setProtocol(oldProtocol => ({ ...oldProtocol!, time: value }));
                                                    setProtocolData("time", value);
                                                  }}
                                                  setNotes={(notes) => {
                                                    var value = (typeof notes === 'function') ? notes(protocol.notes) : notes;
                                                    setProtocol(oldProtocol => ({ ...oldProtocol!, notes: value }));
                                                    setProtocolData("notes", value);
                                                  }}
                            />,
                            <EditProtocolChecklist protocol={protocol}
                                                   template={props.project.baukg?.safetychecklist}
                                                   setChecklist={(checklist) => {
                                                        var value = (typeof checklist === 'function') ? 
                                                            checklist(protocol.checklist) : checklist;
                                                        setProtocol(oldProtocol => ({ ...oldProtocol!, checklist: value }));
                                                        setProtocolData("checklist", value);
                                                   }}
                            
                            />,
                            <EditProtocolDeficits protocol={protocol} 
                                                  parties={props.project.baukg?.parties}
                                                  setDeficits={(deficits) => {
                                                    var value = (typeof deficits === 'function') ? 
                                                        deficits(protocol.deficits) : deficits;
                                                    setProtocol(oldProtocol => ({ ...oldProtocol!, deficits: value }));
                                                    setProtocolData("deficits", value);
                                                  }}
                                                  addAttachments={async (protocolId: string, deficitId: string, files: FileList) => {
                                                    return await API.addDeficitAttachments(props.project.id, protocolId, deficitId, files);
                                                  }}
                                                  queryTemplates={async (searchText: string) => {
                                                    return await API.queryDeficits(searchText);
                                                  }}
                            />,
                            <EditProtocolSummary protocol={protocol} 
                                                 setDanger={(danger) => {
                                                    var value = (typeof danger === 'function') ? 
                                                      danger(protocol.danger) : danger;
                                                    setProtocol(oldProtocol => ({ ...oldProtocol!, danger: value }));
                                                    setProtocolData("danger", value);
                                                 }}
                                                 setDeadline={(deadline) => {
                                                    var value = (typeof deadline === 'function') ? 
                                                      deadline(protocol.deadline) : deadline;
                                                    setProtocol(oldProtocol => ({ ...oldProtocol!, deadline: value }));
                                                    setProtocolData("deadline", value);
                                                 }}
                                                 setResend={(resend) => {
                                                    var value = (typeof resend === 'function') ? 
                                                      resend(protocol.resend) : resend;
                                                    setProtocol(oldProtocol => ({ ...oldProtocol!, resend: value }));
                                                    setProtocolData("resend", value);
                                                 }}
                                                 onSubmit={() => {
                                                    API.submitProtocol(props.project.id, protocol.id)
                                                        .then(result => props.onClose(result));
                                                 }}
                                                 onPreview={() => {
                                                    props.onPreview(props.project.id, protocol.id)
                                                 }}
                            
                            />
                        ][currentStep]   
                    }
                </DrawerBody>              
            </DrawerContent>
        </Drawer>
        </Portal>
    );

}