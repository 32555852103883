import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { IApiProject } from "../../../api/models/IApiProject";
import API from "../../../api/API";
import { TableWithSearch } from "../../cui-pro/TableWithSearch/TableWithSearch";
import { Badge, Box, Button, ButtonGroup, Checkbox, Divider, HStack, Icon, IconButton, Input, InputGroup, InputLeftElement, List, ListItem, Stack, Text, useBreakpointValue, useColorModeValue as mode, VStack, } from "@chakra-ui/react";
import { FiEdit2, FiSearch, FiTrash2, FiPlay, FiRefreshCw, FiPlusSquare, FiFolder } from "react-icons/fi";
import {
    useNavigate 
  } from "react-router-dom";
import { IProjectsProps } from "./IProjects";
import { ShellContentContainer } from "../../cui-pro/ShellWithSidebar/ShellContentContainer";
import { IShellContentContainerProps } from "../../cui-pro/ShellWithSidebar/IShellWithSidebar";

export const Projects: React.FunctionComponent<IProjectsProps> = (props: IProjectsProps) => {

    const { onProjectSelected, projects, onShowProtocols: onAddProtocol } = props;
    const [showActiveProjectsOnly, setShowActiveProjectOnly] = useState(true);

    const isDesktop = useBreakpointValue({ base: false, lg: true });

    const { instance } = useMsal();
    const { accounts } = useMsal();

    const navigate = useNavigate();

    const [filter, setFilter] = useState("");

    const ReloadButton = () => (isDesktop ? 
        (<Button leftIcon={<FiRefreshCw />} variant="primary" onClick={() => props.onReloadProjects()}>Aktualisieren</Button>)
        :
        (<IconButton aria-label='reload' icon={<FiRefreshCw />} variant="primary" onClick={() => props.onReloadProjects()} />)
    );
    const ActiveOnlyCheckbox = () => (<Checkbox defaultChecked={showActiveProjectsOnly} onChange={e => setShowActiveProjectOnly(e.target.checked)}>Nur aktive Projekte anzeigen</Checkbox>);

    return (
        <ShellContentContainer
            leftButton={!isDesktop ? <ActiveOnlyCheckbox /> : <></>}
            rightButton={<ReloadButton />}
            overflowY={true}
            justifyButtons="space-between"
            buttonsInTitleRow={isDesktop}
        >
            <Stack spacing="4" height="100%">
                <Box px={{ base: '2', md: '2' }} pt="2">
                    <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between">
                        <InputGroup maxW={{base: "100%", lg: "md"}}>
                            <InputLeftElement pointerEvents="none">
                            <Icon as={FiSearch} color="muted" boxSize="5" />
                            </InputLeftElement>
                            <Input placeholder="Suchen..." onChange={e => setFilter(e.currentTarget?.value?.toLocaleLowerCase())} />
                        </InputGroup>
                        {isDesktop && <ActiveOnlyCheckbox />}
                    </Stack>
                </Box>

            <Box py="0" overflowY="auto">
                <List listStyleType="none" spacing="4">
                    
                        {projects.filter(project => project.name?.toLowerCase().indexOf(filter) >= 0 || project.id.toLowerCase().indexOf(filter) >= 0)
                        .filter(project => !showActiveProjectsOnly || (project.baukg?.initialized && project.baukg?.active))
                        .map(project => (
                            <ListItem
                                key={project.id}
                                value={project.id}
                                bg="bg-surface"
                                p="4"
                                my="2"
                                mx="2"
                                boxShadow={mode('sm', 'sm-dark')}
                                position="relative"
                                borderRadius="lg"
                                cursor={(project.baukg?.initialized && project.baukg?.active) ? "pointer" : undefined}
                                onClick={() => {
                                    if(project.baukg?.initialized && project.baukg?.active) {
                                        onProjectSelected(project);
                                    }
                                }}
                            >
                                <HStack spacing="4" justifyContent="space-between">
                                    <VStack spacing="2" align="left">
                                        <Text fontWeight="medium" fontSize={{ base: "sm", md: "md" }}>
                                            {project.name}
                                        </Text>
                                        <HStack>
                                            <Text color="muted" fontSize={{ base: "xs", md: "sm" }}>
                                                {`GZ: ${project.id}`}
                                            </Text>
                                            <Badge colorScheme={project.baukg?.initialized ? (project.baukg?.active ? 'green' : 'red') : 'blue'} size="sm">
                                                { project.baukg?.initialized ? (project.baukg?.active ? 'aktiv' : 'inaktiv') : 'nicht initialisiert' }
                                            </Badge>
                                        </HStack>
                                    </VStack>
                                    <HStack spacing="3" justifyContent="end">
                                        {(project.baukg?.initialized === true) && (
                                            <>
                                                <IconButton
                                                    icon={<FiFolder fontSize="1.25rem" />}
                                                    variant="ghost"
                                                    aria-label="Bearbeiten"
                                                    onClick={(e) => { e.stopPropagation(); onAddProtocol(project);}}
                                                />
                                                {
                                                    /*
                                                        <IconButton
                                                            icon={<FiTrash2 fontSize="1.25rem" />}
                                                            variant="ghost"
                                                            aria-label="Löschen"
                                                            visibility={{ base: "collapse", md: "visible" }}
                                                            onClick={(e) => { e.stopPropagation(); alert("delete");}}
                                                        />
                                                    */
                                                }
                                            </>
                                        ) || (
                                            <>
                                                <IconButton
                                                    icon={<FiPlay fontSize="1.25rem" />}
                                                    variant="ghost"
                                                    aria-label="Aktivieren"
                                                    onClick={(e) => { 
                                                        e.stopPropagation(); 
                                                        props.onActivateProject(project.id);
                                                    }}
                                                />
                                            </>
                                        )}
                                        
                                    </HStack>
                                </HStack>
                            </ListItem>
                        ))}
                </List>
            </Box>
            </Stack>
        </ShellContentContainer>
    );
}

/*

no pagination for now...

<Box px={{ base: '4', md: '6' }} pb="5">
            <HStack spacing="3" justify="space-between">
            <Text color="muted" fontSize="sm">
                  Showing 1 to 5 of 42 results
                </Text>
              <ButtonGroup
                spacing="3"
                justifyContent="space-between"
                width={{ base: 'full', md: 'auto' }}
                variant="secondary"
              >
                <Button>Previous</Button>
                <Button>Next</Button>
              </ButtonGroup>
            </HStack>
          </Box>




*/