import { As, Box, Button, ButtonGroup, ButtonProps, Container, useColorModeValue, BoxProps, Divider, Textarea ,Flex, Heading, useColorModeValue as mode, HStack, Icon, IconButton, IconButtonProps, Input, Stack, Text, useEditableControls, VStack, StackDivider, InputGroup, InputLeftElement, useBreakpointValue, Badge, Table, Tbody, Td, Th, Thead, Tr, ListItem, List, Square, UnorderedList, EditableInput, Editable, EditablePreview, ListIcon, useEditableState } from '@chakra-ui/react'
import * as React from 'react'

  import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
  } from '@chakra-ui/react'

import { useState, useRef } from 'react';
import { IApiProjectBauKGCoreData } from '../../../../api/models/IApiProject';
import { IProjectDocumentsProps } from './IProjectDocuments';
import { FiArrowDown, FiArrowUp, FiCheck, FiDelete, FiEdit2, FiFile, FiFileText, FiLink, FiLink2, FiPlus, FiTrash, FiTrash2 } from 'react-icons/fi';
import { ShellContentContainer } from '../../../cui-pro/ShellWithSidebar/ShellContentContainer';
import { IApiBauKGDocument } from '../../../../api/models/IApiBauKGDocument';
import API from '../../../../api/API';

export const ProjectDocuments = (props: IProjectDocumentsProps ) => {

    const { documents } = props;
    const inputRef = useRef<any>();

    const isDesktop = useBreakpointValue({ base: false, lg: true });
    const showTable = useBreakpointValue({ base: false, xl: true });

    return (
        <ShellContentContainer
            leftButton={undefined}
            rightButton={
                <FormControl>
                    <InputGroup>
                        <InputLeftElement
                            pointerEvents="none">
                            <Icon as={FiFile} />
                        </InputLeftElement>
                        <input type='file'
                            name={"fileinput"}
                            ref={inputRef}
                            style={{display: 'none'}} 
                            onChange={(e) => e.target.files && props.onAdd(e.target.files)}
                        />
                        <Input
                            placeholder={"Dokument hochladen ..."}
                            onClick={() => inputRef.current.click()}
                            readOnly={true}
                            onDragOver={e => e.preventDefault()}
                            onDrop={e => { e.preventDefault(); props.onAdd(e.dataTransfer.files); }}
                        />
                    </InputGroup>
                </FormControl>
            }
        >
            <Container p={{ base: 0, md: 0 }} mx={0} w='100%' maxW='unset'>
                <Box
                    bg="bg-surface"
                    boxShadow={{ base: 'none', md: useColorModeValue('sm', 'sm-dark') }}
                    borderRadius={useBreakpointValue({ base: 'none', md: 'lg' })}
                >
                    {(showTable && (
                            <Table>
                                <Thead>
                                    <Tr>
                                        <Th w='50%'>Name</Th>
                                        <Th w='20%'>Größe</Th>
                                        <Th w='20%'>Änderungsdatum</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {documents.map(document => (
                                        <Tr key={document.url}>
                                            <Td>
                                                <Button variant='ghost' onClick={() => window.open(document.url, "_blank")}>
                                                    {document.name}
                                                </Button>
                                                
                                            </Td>
                                            <Td>
                                                {`${(document.size / 1024 / 1024).toFixed(2)} MB`}
                                            </Td>
                                            <Td>
                                                {document.timeLastModified.toLocaleString()}
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                    )) || (
                        <List listStyleType="none" spacing="4">
                            {documents.map((document) => (
                                <ListItem
                                    key={document.url}
                                    value={document.url}
                                    bg="bg-surface"
                                    p="4"
                                    my="2"
                                    mx="2"
                                    boxShadow={mode('sm', 'sm-dark')}
                                    position="relative"
                                    borderRadius="lg"
                                    onClick={() => window.open(document.url, "_blank")}
                                >
                                    <VStack spacing="2" align="start">
                                        <Text fontWeight="medium" fontSize={{ base: "sm", md: "md" }}>
                                            {document.name}
                                        </Text>
                                        <Badge colorScheme={'gray'} size={{ base: "xs", md: "sm" }}>
                                            {`Änderungsdatum: ${document.timeLastModified.toLocaleString("de-DE").substring(0, 10).split('-').reverse().reduce((prev, curr, i) => `${prev}.${curr}`, "").substring(1, 11)}` /* temporary solution?! */}
                                        </Badge>
                                        <Badge colorScheme={'gray'} size={{ base: "xs", md: "sm" }}>
                                            {`Größe: ${(document.size / 1024 / 1024).toFixed(2)} MB`}
                                        </Badge>
                                    </VStack>
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Box>
            </Container>
        </ShellContentContainer>
    );
}