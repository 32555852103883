import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import React, { useRef } from 'react'
import { FiDownloadCloud } from 'react-icons/fi'
import { IShellWithSidebarContext, IShellWithSidebarProps } from './IShellWithSidebar'
import { Navbar } from './Navbar'
import { Sidebar } from './Sidebar'

export const ShellWithSidebarContext = React.createContext<IShellWithSidebarContext>({ pageName: "", companyName: "", projectName: ""});

export const ShellWithSidebar: React.FunctionComponent<IShellWithSidebarProps> = (props) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  
  const navbar = document.getElementById("navbar"); // "hack", but works...

  return (
    <ShellWithSidebarContext.Provider value={{ pageName: props.pageName, companyName: props.companyName, projectName: props.projectName }}>
      <Flex
        as="section"
        direction={{ base: 'column', lg: 'row' }}
        height="100vh"
        maxHeight="100vh"
        overflow="hidden"
        bg="bg-canvas"
      >
        {isDesktop ? <Sidebar { ...props } /> : <Navbar { ...props } />}
        <Box bg="bg-surface" pt={{ base: '0', lg: '3' }} flex="1" height={"full"} 
             maxHeight={isDesktop ? undefined : `calc(100vh - ${navbar?.clientHeight}px)`} 
        >
          <Box bg="bg-canvas" borderTopLeftRadius={{ base: 'none', lg: '2rem' }} height={"full"}>
            <Container py="8" height="full">
              {props.children}
            </Container>
          </Box>
        </Box>
      </Flex>
    </ShellWithSidebarContext.Provider>
  )
}
