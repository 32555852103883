import { As, Box, Button, ButtonProps, Heading, HStack, Icon, Stack, Text, useBreakpointValue, VStack } from '@chakra-ui/react'
import * as React from 'react'
import { useContext } from 'react'
import { IShellContentContainerProps as IShellContentContainerProps } from './IShellWithSidebar'
import { NavbarItems } from './NavbarItems'
import { ShellWithSidebarContext } from './ShellWithSidebar'

const defaultProps: IShellContentContainerProps = {
    overflowY: true,
    showTitle: true,
    leftButton: undefined,
    rightButton: undefined,
    children: undefined,
    justifyButtons: undefined,
    buttonsInTitleRow: false,
};

const ShellContentContainer: React.FunctionComponent<IShellContentContainerProps> = (props: IShellContentContainerProps) => {

  const sidebarContext = useContext(ShellWithSidebarContext);

  const headingSize = useBreakpointValue({ base: 'xs', lg: 'sm' });

  const Buttons = () => (
    <HStack spacing="3" justifyContent={props.justifyButtons} w={props.buttonsInTitleRow ? undefined : "100%"}>
        {props.leftButton}
        {props.rightButton}
    </HStack>
  );

  return (
    <Stack spacing={{ base: '8', lg: '6' }} height="full"> 
        {props.showTitle && (
            <Stack
                spacing="4"
                direction={{ base: 'column', lg: 'row' }}
                justify="space-between"
                align={{ base: 'start', lg: 'center' }}
            >
                <HStack justifyContent={props.buttonsInTitleRow ? "space-between" : undefined} width="100%">
                    <VStack spacing="1" alignItems="start">
                        <Heading size={headingSize} fontWeight="medium">
                            {sidebarContext.projectName?.length > 0 ? sidebarContext.projectName : sidebarContext.companyName }
                        </Heading>
                        <Text color="muted">
                            {sidebarContext.pageName}
                        </Text>
                    </VStack>
                    {(props.buttonsInTitleRow && (<Buttons />))}
                </HStack>
                {(!props.buttonsInTitleRow) && (props.leftButton || props.rightButton) && (<Buttons />)}
            </Stack>
        )}
        <Box mt={`${props.showTitle ? 4 : 0} !important`} bg="bg-surface" borderRadius="lg" borderWidth="1px" height="full" overflowY={ props.overflowY ? "auto" : "hidden" } overflowX="clip">
            {props.children}
        </Box>
    </Stack>
  )
}

ShellContentContainer.defaultProps = defaultProps;

export { ShellContentContainer };