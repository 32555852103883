import { As, Box, Button, ButtonGroup, ButtonProps, Container, useColorModeValue, BoxProps, Divider, Textarea ,Flex, Heading, useColorModeValue as mode, HStack, Icon, IconButton, IconButtonProps, Input, Stack, Text, useEditableControls, VStack, StackDivider, InputGroup, InputLeftElement, useBreakpointValue, Badge, Table, Tbody, Td, Th, Thead, Tr, ListItem, List, Square, UnorderedList, EditableInput, Editable, EditablePreview, ListIcon, useEditableState } from '@chakra-ui/react'
import * as React from 'react'
import { useState, useRef } from 'react';
import { FiArrowDown, FiArrowUp, FiCheck, FiDelete, FiEdit2, FiFileText, FiPlus, FiTrash, FiTrash2 } from 'react-icons/fi';
import { ISafetyChecklistEditorProps } from './ISafetyChecklistEditor';

interface IEditableEditControlsProps {
    submitButtonSize: string;
    editButtonSize: string;
}
function EditableEditControls({ submitButtonSize, editButtonSize }: IEditableEditControlsProps) {
    const {
      isEditing,
      getEditButtonProps,
      getSubmitButtonProps
    } = useEditableControls();

    return isEditing ? (
        <IconButton size={submitButtonSize} variant='ghost' icon={<FiCheck />} {...getSubmitButtonProps() as IconButtonProps} />
    ) : (
        <IconButton size={editButtonSize} variant='ghost' icon={<FiEdit2 />} {...getEditButtonProps() as IconButtonProps} />
    );
}


export const SafetyChecklistEditor = (props: ISafetyChecklistEditorProps) => {

    const { checklist, setChecklist } = props;
    const [topics, setTopics] = useState<string[]>([]);

    React.useEffect(() => {
        if(checklist) {
            setTopics(Object.getOwnPropertyNames(checklist));
        }
    }, [checklist]);

    const addTopic = () => {
        var topic = "Thema " + (topics.length + 1);
        setChecklist(oldChecklist => {
            var newChecklist = { ...oldChecklist };
            newChecklist[topic] = [];
            return newChecklist
        });
        setTopics(oldTopics => {
            var newTopics = [...oldTopics];
            newTopics.push(topic);
            return newTopics;
        });
    }

    const updateTopic = (oldValue: string, newValue: string) => {
        var index = topics.indexOf(oldValue);
        setChecklist(oldChecklist => {
            var newChecklist = { ...oldChecklist };
            newChecklist[newValue] = oldChecklist[oldValue];
            delete newChecklist[oldValue];
            return newChecklist
        });
        setTopics(oldTopics => {
            var newTopics = [...oldTopics];
            newTopics.splice(index, 1, newValue);
            return newTopics;
        });
    };

    const deleteTopic = (topic: string) => {
        setChecklist(oldChecklist => {
            var newChecklist = { ...oldChecklist };
            delete newChecklist[topic];
            return newChecklist
        });
        setTopics(oldTopics => {
            var newTopics = [...oldTopics];
            newTopics.splice(newTopics.indexOf(topic), 1);
            return newTopics;
        });
    }

    const addItem = (topic: string) => {
        setChecklist(oldChecklist => {
            var newChecklist = { ...oldChecklist };
            newChecklist[topic] = [...(oldChecklist[topic])];
            newChecklist[topic].push(topic + " Kontrollbereich Nr. " + newChecklist[topic].length);
            return newChecklist
        });
    }

    const updateItem = (topic: string, index: number, newValue: string) => {
        setChecklist(oldChecklist => {
            var newChecklist = { ...oldChecklist };
            newChecklist[topic] = [...(oldChecklist[topic])];
            newChecklist[topic].splice(index, 1, newValue);
            return newChecklist
        });
    }

    const deleteItem = (topic: string,  itemIndex: number) => {
        setChecklist(oldChecklist => {
            var newChecklist = { ...oldChecklist };
            newChecklist[topic] = [...(oldChecklist[topic])];
            newChecklist[topic].splice(itemIndex, 1);
            return newChecklist
        });
    }

    const moveItemUp = (topic: string, itemIndex: number) => {
        setChecklist(oldChecklist => {
            var newChecklist = { ...oldChecklist };
            newChecklist[topic] = [...(oldChecklist[topic])];
            var item = newChecklist[topic][itemIndex];
            newChecklist[topic].splice(itemIndex, 1);
            newChecklist[topic].splice(itemIndex - 1, 0, item);
            return newChecklist
        });
    }

    const moveItemDown = (topic: string, itemIndex: number) => {
        setChecklist(oldChecklist => {
            var newChecklist = { ...oldChecklist };
            newChecklist[topic] = [...(oldChecklist[topic])];
            var item = newChecklist[topic][itemIndex];
            newChecklist[topic].splice(itemIndex, 1);
            newChecklist[topic].splice(itemIndex + 1, 0, item);
            return newChecklist
        });
    }

    return (
        <List listStyleType="none" p={{ base: 0, md: 4 }}>
          <Stack spacing="3" width="full">
            {topics.map((topic, topicIndex) => (
                    <ListItem
                        key={`${topic}-${topicIndex}`}
                        value={topic}
                        bg="bg-surface"
                        p="4"
                        boxShadow={mode('sm', 'sm-dark')}
                        position="relative"
                        borderRadius="lg"
                    >
                            <Stack spacing="3">
                                    <Editable defaultValue={topic} 
                                            fontSize="lg" 
                                            fontWeight="medium" 
                                            onSubmit={e => {
                                                if(e.length > 0 && e !== topic && !(topics.indexOf(e) >= 0)) {
                                                    updateTopic(topic, e)
                                                }
                                            }}
                                            isPreviewFocusable={false}
                                    >
                                        <HStack>
                                            <EditablePreview />
                                            <EditableInput />
                                            <EditableEditControls submitButtonSize='md' editButtonSize='sm' />
                                        </HStack>
                                    </Editable>                                
                                <Box borderWidth={{ base: '0', md: '1px' }} p={{ base: '0', md: '4' }} borderRadius="lg">                               
                                    <List spacing={1}>
                                        {checklist[topic] && checklist[topic].map((item, itemIndex, itemArray) => (
                                            <ListItem key={`${topic}-${itemIndex}-${item}`}>
                                                <HStack>
                                                    <HStack spacing={0}>
                                                        <IconButton aria-label='down' 
                                                                    size='xs' 
                                                                    icon={<FiArrowDown />} 
                                                                    variant='ghost'
                                                                    isDisabled={itemIndex == itemArray.length - 1}
                                                                    onClick={() => moveItemDown(topic, itemIndex)}
                                                        />
                                                        <IconButton aria-label='up' 
                                                                    size='xs' 
                                                                    icon={<FiArrowUp />} 
                                                                    variant='ghost' 
                                                                    isDisabled={itemIndex == 0}
                                                                    onClick={() => moveItemUp(topic, itemIndex)}
                                                        />
                                                    </HStack>
                                                    <Editable defaultValue={item} 
                                                              w='100%'
                                                              onSubmit={e => {
                                                                  if(e.length === 0) {
                                                                    deleteItem(topic, itemIndex);
                                                                  } else if(e != item) {
                                                                    updateItem(topic, itemIndex, e);
                                                                  }
                                                              }}
                                                              isPreviewFocusable={false}
                                                    >
                                                        <HStack>
                                                            <EditablePreview />
                                                            <EditableInput />
                                                            <EditableEditControls submitButtonSize='xs' editButtonSize='xs' />
                                                        </HStack>
                                                    </Editable>
                                                    <IconButton aria-label='delete' 
                                                                size='xs' 
                                                                icon={<FiTrash2 /> } 
                                                                variant='ghost' 
                                                                color='red.500' 
                                                                onClick={() => deleteItem(topic, itemIndex)}
                                                    />
                                                </HStack>
                                            </ListItem>
                                        ))}
                                        <ListItem key={topic + "-add"}>
                                            <Button leftIcon={<FiPlus />} 
                                                    mt={2} 
                                                    color='green' 
                                                    size='xs' 
                                                    variant='outline'
                                                    onClick={() => addItem(topic)}
                                            >
                                                Neuer Eintrag
                                            </Button>
                                        </ListItem>
                                    </List>
                                </Box>
                                <Button leftIcon={<FiTrash2 />} 
                                        px={8}
                                        color='red' 
                                        size='sm' 
                                        variant='outline'
                                        fontWeight='normal'
                                        alignSelf={'end'}
                                        onClick={() => deleteTopic(topic)}
                                >
                                    Thema entfernen
                                </Button>
                            </Stack>
                    </ListItem>
                )
              )}
            <Button leftIcon={<FiPlus />} 
                                        px={4}
                                        mb='2 !important'
                                        mx='0 !important'
                                        color='green' 
                                        size='md' 
                                        variant='outline'
                                        fontWeight='normal'
                                        onClick={() => addTopic()}
                                >
                                    Neues Thema
                                </Button>
          </Stack>
        </List>
    );
}