import React, { useState, useEffect, useRef } from "react";
import { useMsal } from "@azure/msal-react";
import { IApiProject } from "../../../api/models/IApiProject";
import API from "../../../api/API";
import { TableWithSearch } from "../../cui-pro/TableWithSearch/TableWithSearch";
import { Badge, Box, Button, ButtonGroup, Container, Divider, Flex, HStack, Icon, IconButton, Input, InputGroup, InputLeftElement, List, ListItem, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Tag, TagLeftIcon, Text, Textarea, useBreakpointValue, useColorModeValue as mode, VStack, } from "@chakra-ui/react";
import { FiEdit2, FiSearch, FiTrash2, FiPlay, FiMail, FiPlus, FiX, FiSave } from "react-icons/fi";
import {
    useNavigate 
  } from "react-router-dom";
import { ShellContentContainer } from "../../cui-pro/ShellWithSidebar/ShellContentContainer";
import { ISettingsProps } from "./ISettings";
import { DeleteConfirmationPopover } from "../deleteconfirmationpopover/DeleteConfirmationPopover";

interface IConfigItemEditorProps {
    items: any[];
    inputType: any;
    onFilterChanged(filter: string): void;
    onAdd(value: string): void;
    onRenderItem(item: any): JSX.Element;
    onDelete(item: any): void;
}

export const ConfigItemEditor: React.FunctionComponent<IConfigItemEditorProps> = (props: IConfigItemEditorProps) => {

    const [addMode, setAddMode] = useState(false);
    const [newEntry, setNewEntry] = useState("");

    const inputRef = useRef<any>();

    return (
        <VStack h="inherit" alignItems='start'>
            <HStack w='100%'>
                <Input id='add-input' as={props.inputType}
                    ref={inputRef} 
                    placeholder={addMode ? '<Neuer Eintrag>'  : 'Suchen...' } 
                    size='md' 
                    flexGrow={1} 
                    onChange={(e) => addMode ? setNewEntry(e.target.value) : props.onFilterChanged(e.target.value)}
                />
                {(addMode && (
                    <>
                        <IconButton aria-label='discard'
                                    variant='primary' 
                                    icon={<FiX />} 
                                    onClick={(e) => {
                                        //searchInputRef.current.value = "";
                                        inputRef.current.value = "";
                                        setNewEntry("");
                                        setAddMode(false);

                                        props.onFilterChanged("");
                                    }} 
                        />
                        <IconButton aria-label='save'
                                    variant='primary' 
                                    icon={<FiSave />} 
                                    isDisabled={newEntry === ""}
                                    
                                    onClick={() => {
                                        props.onAdd(newEntry);
                                        //searchInputRef.current.value = "";
                                        inputRef.current.value = "";
                                        setNewEntry("");
                                        setAddMode(false);
                                    }} 
                        />
                    </>
                )) || (
                    <IconButton aria-label='add'
                            variant='primary' 
                            icon={<FiPlus />} 
                            onClick={() => { 
                                //searchInputRef.current.value = "";
                                setNewEntry(inputRef.current.value);
                                // = "";
                                //props.onFilterChanged(inputRef.current.value);
                                setAddMode(true);
                            }}
                    />
                )}
            </HStack>
            <Divider />
            <List overflowY='auto' h="100%" w='100%' mt={2} pl={0.5} pr={2}>
                {props.items.map((item, index) => (
                    <ListItem
                        key={index}
                        bg="bg-surface"
                        px="4"
                        py="4"
                        my="2"
                        boxShadow={mode('sm', 'sm-dark')}
                        position="relative"
                        borderRadius="lg"
                    >
                        <HStack>
                            { props.onRenderItem(item) }
                            <DeleteConfirmationPopover
                                header="Eintrag löschen?"
                                body="Dieser Vorgang kann nicht rückgängig gemacht werden!"
                                placement="left"
                                trigger={
                                    <IconButton aria-label='delete' fontSize='sm' icon={<FiTrash2 /> } variant='ghost' 
                                        color='red.500' height='25px' width='25px' minW='25px'
                                    />
                                }
                                onConfirm={() => props.onDelete(item)}
                            />
                        </HStack>
                </ListItem>))}
            </List>
        </VStack>
    );
}