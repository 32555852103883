import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { IApiBauKGChecklistTemplate } from "./models/IApiBauKGChecklistTemplate";
import { IApiBauKGDeficit } from "./models/IApiBauKGDeficit";
import { IApiBauKGDocument } from "./models/IApiBauKGDocument";
import { IApiBauKGProtocol } from "./models/IApiBauKGProtocol";
import { IApiBauKGTrade } from "./models/IApiBauKGTrade";
import { IApiContact } from "./models/IApiContact";
import { IApiProject, IApiProjectBauKGCoreData, IApiProjectBauKGParty } from "./models/IApiProject";



export default class API {

    public static instance: IPublicClientApplication;
    public static account: AccountInfo;

    //private static readonly _baseUrl: string = "http://localhost:7096/api";
    private static readonly _baseUrl: string = "https://app-fpbaukgapp-api.azurewebsites.net/api";

    private static async acquireToken(): Promise<string> {
        var accessTokenRequest = {
            scopes: ["bb2e4708-3c13-4dc1-aaff-1fba0fa6b10b/user_impersonation"], // baukg.app API!
            account: this.account,
        };

        try {
            var atr = await this.instance.acquireTokenSilent(accessTokenRequest);
            return atr.accessToken;
        } catch (ex1) {
            console.log(ex1);
            try{
                await this.instance.acquireTokenRedirect(accessTokenRequest);
                var atr = await this.instance.acquireTokenSilent(accessTokenRequest);
                return atr.accessToken;
            } catch (ex2) {
                console.log(ex2);
                return "";
            }
        }
    }
    
    public static async getProjects() : Promise<IApiProject[]> {
        var result = await fetch(`${this._baseUrl}/Projects`, {
            headers: {
                'Authorization': 'Bearer ' + (await this.acquireToken())
            },
        });
        return result.json();
    }

    public static async activateProject(id: string) : Promise<IApiProject> {
        var result = await fetch(`${this._baseUrl}/Projects/${id}/Activate`, {
            headers: {
                'Authorization': 'Bearer ' + (await this.acquireToken())
            },
        });
        return result.json();
    }

    public static async queryContacts(filterText: string): Promise<IApiContact[]> {
        
        var indexUrl = "https://search-fpsharepoint-contacts.search.windows.net/indexes/index-fpsharepoint-contactstemp/docs?api-version=2021-04-30-Preview";
        var indexKey = "JXGwohCKivhz8axbma5XzQ7bN7TXdwv1sBvclPtTCGAzSeAhPdT6";
        var indexQuery = encodeURI(filterText + (filterText.indexOf(' ') < 0 ? "*" : ""));

        return new Promise(async (resolutionFunc, rejectionFunc) => {
            var result = await fetch(`${indexUrl}&api-key=${indexKey}&search=${indexQuery}`, {
                method: 'GET',
            });
            var data = await result.json();
            resolutionFunc(data.value);
        });
        
        /*
        var result = await fetch(`${this._baseUrl}/Contacts/Query`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + (await this.acquireToken())
            },
            body: JSON.stringify({
                text: filterText
            })
        })
        return result.json();
        */
    }

    public static async getTrades() : Promise<IApiBauKGTrade[]> {
        var result = await fetch(`${this._baseUrl}/Trades`, {
            headers: {
                'Authorization': 'Bearer ' + (await this.acquireToken())
            },
        });
        return result.json();
    }

    public static async addTrade(value: string): Promise<IApiBauKGTrade> {
        var result = await fetch(`${this._baseUrl}/Trades`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + (await this.acquireToken())
            },
            body: JSON.stringify({ value })
        })
        return result.json();
    }

    public static async deleteTrade(id: string): Promise<IApiBauKGTrade> {
        var result = await fetch(`${this._baseUrl}/Trades/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + (await this.acquireToken())
            },
        })
        return result.json();
    }

    public static async addParty(id: string, party: IApiProjectBauKGParty): Promise<IApiProject> {
        var result = await fetch(`${this._baseUrl}/Projects/${id}/Parties`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + (await this.acquireToken())
            },
            body: JSON.stringify(party)
        })
        return result.json();
    }

    public static async updateParty(id: string, party: IApiProjectBauKGParty): Promise<IApiProject> {
        var result = await fetch(`${this._baseUrl}/Projects/${id}/Parties`, {
            method: 'UPDATE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + (await this.acquireToken())
            },
            body: JSON.stringify(party)
        })
        return result.json();
    }

    public static async deleteParty(id: string, party: IApiProjectBauKGParty): Promise<IApiProject> {
        var result = await fetch(`${this._baseUrl}/Projects/${id}/Parties`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + (await this.acquireToken())
            },
            body: JSON.stringify(party)
        })
        return result.json();
    }

    public static async updateCoreData(id: string, coredata: IApiProjectBauKGCoreData) : Promise<IApiProject> {
        var result = await fetch(`${this._baseUrl}/Projects/${id}/CoreData`, {
            method: 'UPDATE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + (await this.acquireToken())
            },
            body: JSON.stringify(coredata)
        })
        return result.json();
    }

    public static async updateSafetyChecklist(id: string, checklist: { [key: string]: string[] }) : Promise<IApiProject> {
        var result = await fetch(`${this._baseUrl}/Projects/${id}/SafetyChecklist`, {
            method: 'UPDATE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + (await this.acquireToken())
            },
            body: JSON.stringify(checklist)
        })
        return result.json();
    }

    public static async addDocuments(id: string, files: FileList) : Promise<IApiBauKGDocument[]> {
        var data = new FormData()
        for(var i = 0; i < files.length; i++) {
            var file = files.item(i);
            if(file) {
                data.append('files', file, file.name);
            }
        }
        var result = await fetch(`${this._baseUrl}/Projects/${id}/Documents`, {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + (await this.acquireToken())
            },
        })
        return result.json();
    }

    public static async getDocuments(id: string) : Promise<IApiBauKGDocument[]> {
        var result = await fetch(`${this._baseUrl}/Projects/${id}/Documents`, {
            headers: {
                'Authorization': 'Bearer ' + (await this.acquireToken())
            },
        });
        return result.json();
    }

    public static async queryDeficits(filterText: string): Promise<IApiBauKGDeficit[]> {
        var result = await fetch(`${this._baseUrl}/Deficits/Query`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + (await this.acquireToken())
            },
            body: JSON.stringify({
                text: filterText
            })
        })
        return result.json();
    }

    public static async addDeficit(value: string): Promise<IApiBauKGDeficit> {
        var result = await fetch(`${this._baseUrl}/Deficits`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + (await this.acquireToken())
            },
            body: JSON.stringify({ value })
        })
        return result.json();
    }

    public static async updateDeficit(id: string, value: string): Promise<IApiBauKGDeficit> {
        var result = await fetch(`${this._baseUrl}/Deficits/${id}`, {
            method: 'UPDATE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + (await this.acquireToken())
            },
            body: JSON.stringify({ value })
        })
        return result.json();
    }

    public static async deleteDeficit(id: string): Promise<IApiBauKGDeficit> {
        var result = await fetch(`${this._baseUrl}/Deficits/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + (await this.acquireToken())
            },
        })
        return result.json();
    }

    public static async getChecklistTemplate() : Promise<IApiBauKGChecklistTemplate> {
        var result = await fetch(`${this._baseUrl}/ChecklistTemplate`, {
            headers: {
                'Authorization': 'Bearer ' + (await this.acquireToken())
            },
        });
        return result.json();
    }

    public static async updateChecklistTemplate(value: IApiBauKGChecklistTemplate) : Promise<IApiBauKGChecklistTemplate> {
        var result = await fetch(`${this._baseUrl}/ChecklistTemplate`, {
            method: 'UPDATE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + (await this.acquireToken())
            },
            body: JSON.stringify(value)
        })
        return result.json();
    }

    public static async createProtocol(id: string) : Promise<IApiProject> {
        var result = await fetch(`${this._baseUrl}/Projects/${id}/Protocols/Create`, {
            headers: {
                'Authorization': 'Bearer ' + (await this.acquireToken())
            },
        });
        return result.json();
    }

    public static async deleteProtocol(projectId: string, protocolId: string) : Promise<IApiProject> {
        var result = await fetch(`${this._baseUrl}/Projects/${projectId}/Protocols/${protocolId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + (await this.acquireToken())
            },
        });
        return result.json();
    }

    public static async addDeficitAttachments(projectId: string, protocolId: string, deficitId: string, files: FileList) : Promise<{ [key: string]: string }> {
        var data = new FormData()
        for(var i = 0; i < files.length; i++) {
            var file = files.item(i);
            if(file) {
                data.append('files', file, file.name);
            }
        }
        var result = await fetch(`${this._baseUrl}/Projects/${projectId}/Protocols/${protocolId}/Deficits/${deficitId.replace("/", "")}/Attachments`, {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + (await this.acquireToken())
            },
        })
        return result.json();
    }

    public static async setProtocolData(projectId: string, protocolId: string, property: string, value: any) : Promise<IApiBauKGProtocol> {
        var result = await fetch(`${this._baseUrl}/Projects/${projectId}/Protocols/${protocolId}/Data/${property}`, {
            method: 'UPDATE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + (await this.acquireToken())
            },
            body: JSON.stringify({ [property]: value })
        })
        return result.json();
    }

    public static async submitProtocol(projectId: string, protocolId: string) : Promise<IApiBauKGProtocol> {
        var result = await fetch(`${this._baseUrl}/Projects/${projectId}/Protocols/${protocolId}/Submit`, {
            method: 'UPDATE',
            headers: {
                'Authorization': 'Bearer ' + (await this.acquireToken())
            },
        });
        return result.json();
    }

    public static async getProtocolPreview(projectId: string, protocolId: string): Promise<Blob> {
        var result = await fetch(`${this._baseUrl}/Projects/${projectId}/Protocols/${protocolId}/Preview`, {
            headers: {
                'Authorization': 'Bearer ' + (await this.acquireToken())
            },
        });
        return result.blob();
    }
}