import { Box, Button, ButtonGroup, Container, Divider, HStack, IconButton, Stack, Text, VStack, StackDivider, useBreakpointValue, Badge, SimpleGrid, FormControl, FormLabel, AccordionPanel, AccordionItem, Accordion, AccordionButton, AccordionIcon, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Image, ExpandedIndex, Select } from '@chakra-ui/react'
import * as React from 'react'
import { useRef, useState } from 'react';
import { FiCheck, FiX, FiSearch, FiSave, FiTrash2, FiPlus, FiImage, FiMaximize2, FiMinimize2 } from 'react-icons/fi';
import { IApiBauKGProtocol, IApiBauKGProtocolDeficit } from '../../../../api/models/IApiBauKGProtocol';
import { RadioIconButtonGroup, RadioIconButton } from '../../../cui-pro/RadioIconButtonGroup/RadioIconButtonGroup';
import { IApiBauKGDeficit } from '../../../../api/models/IApiBauKGDeficit';
import toast from 'react-hot-toast';
import { DeleteConfirmationPopover } from '../../deleteconfirmationpopover/DeleteConfirmationPopover';
import { AutoResizeTextarea } from './AutoSizeTextarea';
import { IApiProjectBauKGParty } from '../../../../api/models/IApiProject';
import Helpers from '../../../../helpers/Helpers';

export interface IEditProtocolDeficitsProps {
    protocol: IApiBauKGProtocol;
    parties: IApiProjectBauKGParty[];
    setDeficits: React.Dispatch<React.SetStateAction<IApiBauKGProtocolDeficit[]>>;
    addAttachments(protocolId: string, deficitId: string, files: FileList): Promise<{ [key: string]: string }>;
    queryTemplates(searchText: string): Promise<IApiBauKGDeficit[]>;
}

export const EditProtocolDeficits = ({ protocol, parties, setDeficits, protocol: { deficits }, addAttachments, queryTemplates }: IEditProtocolDeficitsProps ) => {

    const [searchingTextArea, setSearchingTextArea] = useState<string>("");
    const [searchResults, setSearchResults] = useState<IApiBauKGDeficit[]>([]);
    const textAreaRefs = useRef<{ [key: string]: HTMLTextAreaElement | null }>({});
    const fileInputRefs = useRef<{ [key: string]: HTMLInputElement | null }>({});

    const [timestamp, setTimestamp] = useState(Date.now()); // workaround to get unique accordion keys... (necessary when deleting items)

    const isDesktop = useBreakpointValue({ base: false, lg: true })

    const [expanded, setExpanded] = useState<ExpandedIndex>(deficits.map((_, i) => i));

    const getPartyName = (party: IApiProjectBauKGParty): string => {
        if(!party.contact) {
            return "";
        } else {
            return `${party.contact.name.length > 0 ? party.contact.name : `${party.contact.firstName} ${party.contact.lastName}`}`;
        }
    };

    return (
        <>
        <Modal isOpen={searchingTextArea.length > 0} onClose={() => setSearchingTextArea("")} size={{ base: "full", md: "lg" }} scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize='lg' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    Vorlage wählen
                    <IconButton aria-label='close' icon={<FiX />} 
                                variant="ghost" p={'0'} m={'0'} h="30px" w="30px" 
                                onClick={() => {
                                    setSearchingTextArea("");                                  
                                    setSearchResults([]);
                                }} 
                            />
                </ModalHeader>
                <Divider />
                <ModalBody px={2}>
                    <Box bg="bg-surface">
                        <Stack divider={<StackDivider />} spacing="4" pt={2}>
                            {searchResults.map((result, index) => (
                                <Text key={result.id} fontSize="sm" px="4" fontWeight="medium" color="emphasized" cursor="pointer"
                                      _hover={{
                                          color: "blue.600"
                                      }}
                                      onClick={() => {
                                        textAreaRefs.current[searchingTextArea]!.value = result.value;
                                        var [deficitIndex, descriptionIndex] = searchingTextArea.substring(1).split('d').map(id => parseInt(id));
                                        setDeficits(oldDeficits => {
                                            var newDeficits = [...oldDeficits];
                                            var newDescriptions = [...oldDeficits[deficitIndex].descriptions];
                                            newDescriptions.splice(descriptionIndex, 1, {
                                                ...oldDeficits[deficitIndex].descriptions[descriptionIndex],
                                                text: result.value
                                            });
                                            newDeficits.splice(deficitIndex, 1, {
                                                ...oldDeficits[deficitIndex],
                                                descriptions: newDescriptions
                                            });
                                            return newDeficits;
                                        });
                                        setSearchingTextArea("");                                  
                                        setSearchResults([]);
                                      }}
                                >
                                    {result.value}
                                </Text>
                            ))}
                            <StackDivider />
                        </Stack>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
        <Container flexGrow="1" display="flex" flexDirection="column" overflow="hidden" px={2} hidden={!isDesktop && searchingTextArea.length > 0}>
            <Accordion allowMultiple={true} defaultIndex={expanded} index={expanded} flexGrow={1} overflow="auto" 
                       onChange={(expandedIndex: ExpandedIndex) => setExpanded(expandedIndex)}
            >
                {deficits.map((deficit, deficitIndex) => (
                    <AccordionItem key={`${deficitIndex}-${timestamp}`}>
                        <h2>
                            <AccordionButton>
                                <HStack flex='1' >
                                    <Box textAlign='left' fontWeight='semibold' fontSize='18px'>Nr. {deficit.id}</Box>
                                    {(deficit.id.startsWith(`${protocol.id}/`)) && (
                                        <Button leftIcon={<FiTrash2 />} color='red' size='sm' variant='ghost'                                            
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setDeficits(oldDeficits => {
                                                    var newDeficits = [...oldDeficits];
                                                    newDeficits.splice(deficitIndex, 1);
                                                    var count = 1;
                                                    var expanded: number[] = [];
                                                    for(var i = 0; i < newDeficits.length; i++) {
                                                        if(newDeficits[i].id.startsWith(`${protocol.id}/`)) {
                                                            newDeficits[i] = {
                                                                ...newDeficits[i], 
                                                                id: `${protocol.id}/${count}`
                                                            };
                                                            count++;
                                                            expanded.push(i);
                                                        }
                                                    }
                                                    setExpanded(expanded);
                                                    return newDeficits;
                                                });
                                                setTimestamp(Date.now());
                                            }}
                                        >
                                            entfernen
                                        </Button>  
                                    )}
                                </HStack>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel>
                            <VStack spacing={4}>
                                <FormControl>
                                    <FormLabel htmlFor='responsibility' 
                                               style={{ fontSize: '14px' }}
                                    >
                                        Zuständigkeit
                                    </FormLabel>
                                    <Select id='responsibility' 
                                            style={{ fontSize: '14px' }} 
                                            defaultValue={deficit.responsibility}
                                            onChange={(e) => setDeficits(oldDeficits => {
                                                var newDeficits = [...oldDeficits];
                                                newDeficits.splice(deficitIndex, 1, {
                                                    ...oldDeficits[deficitIndex],
                                                    responsibility: e.target.value
                                                });
                                                return newDeficits;
                                            })}
                                    >
                                        <option value={'ALLE'}>{'ALLE'}</option>
                                        {parties.map(p => getPartyName(p)).map(name => (<option value={name}>{name}</option>))}
                                        {(['ALLE', ...parties.map(p => getPartyName(p))].filter(name => name === deficit.responsibility).length === 0 && (
                                            <option value={deficit.responsibility}>{deficit.responsibility}</option>  
                                        )) || (<></>)}
                                    </Select>
                                </FormControl>
                                <FormControl>
                                    <FormLabel htmlFor='descriptions' 
                                               style={{ fontSize: '14px' }}
                                    >
                                        Beschreibung
                                    </FormLabel>
                                    <VStack id='descriptions' >
                                        {deficit.descriptions.map((description, descriptionIndex) => (
                                            <VStack w="100%" spacing={0} >
                                                {(description.source !== protocol.id) && (
                                                    <Badge fontSize='12px' w='100%' textAlign='left' borderBottomRadius='0' borderTopRadius='8px' >
                                                        {`Eintrag vom ${Helpers.formatDate(description.date)} (Protokoll ${description.source})`}
                                                    </Badge>
                                                )}
                                                <AutoResizeTextarea size="sm"
                                                          defaultValue={description.text}
                                                          readOnly={description.source !== protocol.id}
                                                          borderTopRadius={description.source !== protocol.id ? 0 : undefined}
                                                          borderBottomRadius={description.source === protocol.id ? 0 : undefined}
                                                          ref={element => {
                                                            textAreaRefs.current[`d${deficitIndex}d${descriptionIndex}`] = element;
                                                          }}
                                                          onBlur={e => setDeficits(oldDeficits => {
                                                            var newDeficits = [...oldDeficits];
                                                            var newDescriptions = [...oldDeficits[deficitIndex].descriptions];
                                                            newDescriptions.splice(descriptionIndex, 1, {
                                                                ...oldDeficits[deficitIndex].descriptions[descriptionIndex],
                                                                text: e.currentTarget.value
                                                            });
                                                            newDeficits.splice(deficitIndex, 1, {
                                                                ...oldDeficits[deficitIndex],
                                                                descriptions: newDescriptions
                                                            });
                                                            return newDeficits;
                                                        })}
                                                        />
                                                {(description.source === protocol.id) && (
                                                    <ButtonGroup size='sm' isAttached variant='outline' display="flex" w="100%">
                                                        {/* {isDesktop && ( */}
                                                            <DeleteConfirmationPopover
                                                                header="Eintrag löschen?"
                                                                body="Dieser Vorgang kann nicht rückgängig gemacht werden!"
                                                                placement='right'
                                                                trigger={
                                                                    <IconButton aria-label='Delete Entry' 
                                                                        flexGrow={1} 
                                                                        icon={<FiTrash2 />} 
                                                                        borderTopLeftRadius={0} 
                                                                    />
                                                                }
                                                                onConfirm={() => setDeficits(oldDeficits => {
                                                                    var newDeficits = [...oldDeficits];
                                                                    var newDescriptions = [...(oldDeficits[deficitIndex].descriptions)];
                                                                    newDescriptions.splice(descriptionIndex, 1);
                                                                    newDeficits.splice(deficitIndex, 1, {
                                                                        ...oldDeficits[deficitIndex],
                                                                        descriptions: newDescriptions
                                                                    });
                                                                    return newDeficits;
                                                                })}
                                                            />
                                                        {/* )} */}
                                                        <IconButton aria-label='Save as Template' 
                                                                    flexGrow={!isDesktop ? 1 : 1} 
                                                                    icon={<FiSave />} 
                                                                    isActive={description.save}
                                                                    _active={{
                                                                        bg: 'blue.200'
                                                                    }}
                                                                    onClick={() => setDeficits(oldDeficits => {
                                                                        var newDeficits = [...oldDeficits];
                                                                        var newDescriptions = [...oldDeficits[deficitIndex].descriptions];
                                                                        newDescriptions.splice(descriptionIndex, 1, {
                                                                            ...oldDeficits[deficitIndex].descriptions[descriptionIndex],
                                                                            save: !oldDeficits[deficitIndex].descriptions[descriptionIndex].save
                                                                        });
                                                                        newDeficits.splice(deficitIndex, 1, {
                                                                            ...oldDeficits[deficitIndex],
                                                                            descriptions: newDescriptions
                                                                        });
                                                                        return newDeficits;
                                                                    })}
                                                                    borderTopLeftRadius={!isDesktop ? 0 : undefined} 
                                                        />
                                                        <IconButton aria-label='Search Templates' 
                                                                    flexGrow={!isDesktop ? 1 : 1} 
                                                                    icon={<FiSearch />} 
                                                                    borderTopRightRadius={0}
                                                                    onClick={(e) => {
                                                                        const textAreaId = `d${deficitIndex}d${descriptionIndex}`;
                                                                        const text = textAreaRefs.current[textAreaId]?.value;
                                                                        if(text !== undefined && text.length >= 4) {
                                                                            queryTemplates(text).then(result => {
                                                                                if(result && result.length > 0) {
                                                                                    setSearchResults(result);
                                                                                    setSearchingTextArea(textAreaId);
                                                                                } else {
                                                                                    toast.error(`Keine passenden Vorlagen gefunden!`, {
                                                                                        position: "bottom-center"
                                                                                    });
                                                                                }
                                                                            });
                                                                        } else {
                                                                            toast.error(`Für die Suche sind mindestens vier Zeichen erforderlich!`, {
                                                                                position: "bottom-center"
                                                                            });
                                                                        }
                                                                    }}
                                                        />
                                                        <IconButton aria-label='Add Attachment' 
                                                                    flexGrow={!isDesktop ? 1 : 1} 
                                                                    icon={<FiImage />} 
                                                                    borderTopRightRadius={0}
                                                                    onClick={() => fileInputRefs.current[`d${deficitIndex}d${descriptionIndex}`]!.click()}
                                                        />
                                                    </ButtonGroup>
                                                )}
                                                <FormControl>
                                                        <SimpleGrid spacing={{ base: "10px" }} mt={2} mx={2} columns={isDesktop ? 4 : 5}>
                                                                {description.attachments.map(attachment => (
                                                                    <Image
                                                                        boxSize={{ base: "100px", md: "150px" }}
                                                                        objectFit='contain'
                                                                        src={attachment.url}
                                                                        cursor="pointer"
                                                                        onClick={() => window.open(attachment.url, "_blank")}
                                                                        border={"solid"}
                                                                        borderWidth={"1px"}
                                                                        borderColor={"gray.200"}
                                                                    />
                                                                ))}
                                                        </SimpleGrid>
                                                        {(description.source === protocol.id) && (
                                                            <input type='file'
                                                                name={"fileinput"}
                                                                ref={element => {
                                                                    fileInputRefs.current[`d${deficitIndex}d${descriptionIndex}`] = element;
                                                                }}
                                                                style={{display: 'none'}} 
                                                                onChange={async (e) => {
                                                                    if(e.target.files) {
                                                                        var fileList = await addAttachments(protocol.id, deficit.id, e.target.files);
                                                                        setDeficits(oldDeficits => {
                                                                            var newDeficits = [...oldDeficits];
                                                                            var newDescriptions = [...(oldDeficits[deficitIndex].descriptions)];
                                                                            var newAttachments = [...(oldDeficits[deficitIndex].descriptions[descriptionIndex].attachments)];
                                                                            Object.getOwnPropertyNames(fileList).forEach(fileName => {
                                                                                newAttachments.push({ 
                                                                                    url: fileList[fileName],
                                                                                    source: protocol.id,
                                                                                    name: fileName
                                                                                });
                                                                            });
                                                                            newDescriptions.splice(descriptionIndex, 1, {
                                                                                ...oldDeficits[deficitIndex].descriptions[descriptionIndex],
                                                                                attachments: newAttachments
                                                                            });
                                                                            newDeficits.splice(deficitIndex, 1, {
                                                                                ...oldDeficits[deficitIndex],
                                                                                descriptions: newDescriptions
                                                                            });
                                                                            return newDeficits;
                                                                        });
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    </FormControl>
                                                    <Divider my={"2 !important"} />
                                            </VStack>
                                        ))}
                                    </VStack>
                                    <Button leftIcon={<FiPlus />} 
                                            mt={4} 
                                            color='green' 
                                            size='sm' 
                                            variant='outline'
                                            w="100%"
                                            onClick={() => {
                                                const index = deficits.indexOf(deficit);
                                                setDeficits(oldDeficits => {
                                                    var newDeficits = [...oldDeficits];
                                                    var newDescriptions = [...oldDeficits[index].descriptions];
                                                    newDescriptions.push({ 
                                                        text: "",
                                                        source: protocol.id,
                                                        date: "",
                                                        save: false,
                                                        attachments: []
                                                    });
                                                    newDeficits.splice(index, 1, {
                                                       ...oldDeficits[index],
                                                       descriptions: newDescriptions
                                                    });
                                                    return newDeficits;
                                                });
                                            }}
                                    >
                                        Eintrag
                                    </Button>
                                </FormControl>
                                <FormControl>
                                    <FormLabel htmlFor='status' 
                                               style={{ fontSize: '12px' }}
                                    >
                                        Status
                                    </FormLabel>
                                    <RadioIconButtonGroup key={`status-${deficit.id}`} 
                                                          defaultValue={`${deficit.status}`}
                                                          size={'md'}
                                                          display="flex"
                                                          mt={2} 
                                                          onChange={e => {
                                                            if(e !== "false" && e !== "true") { return; }
                                                            var status = (e === "false") ? false : true; console.log(e);
                                                            setDeficits(oldDeficits => {
                                                                var newDeficits = [...oldDeficits];
                                                                newDeficits.splice(deficitIndex, 1, {
                                                                   ...oldDeficits[deficitIndex],
                                                                   status
                                                                });
                                                                return newDeficits;
                                                            });
                                                        }}
                                    >
                                        <RadioIconButton
                                            value="false"
                                            aria-label="Align left"
                                            icon={<FiX fontSize="1.125rem" />}
                                            grow={true}
                                            h={8}
                                            _checked={{
                                                bg: 'red.200'
                                            }}
                                        />
                                        <RadioIconButton
                                            value="true"
                                            aria-label="Align center"
                                            icon={<FiCheck fontSize="1.125rem" />}
                                            grow={true}
                                            h={8}
                                            _checked={{
                                                bg: 'green.200'
                                            }}
                                        />
                                    </RadioIconButtonGroup>
                                </FormControl>
                            </VStack>
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
            <Divider my={2} />
            <HStack>
                <IconButton aria-label='collapse' 
                            flexGrow={1}
                            variant="outline" 
                            icon={<FiMinimize2 />} 
                            onClick={() => setExpanded([])}
                />
                <IconButton aria-label='expand' 
                            flexGrow={1}
                            variant="outline" 
                            icon={<FiMaximize2 />} 
                            onClick={() => setExpanded(deficits.map((_, i) => i))}
                />
                <Button leftIcon={<FiPlus />} 
                        variant="outline" 
                        flexShrink={0}
                        flexGrow={6}
                        color='red' 
                        onClick={() => {
                            const expand = deficits.length;
                            setDeficits(old => ([...old, {
                                id: `${protocol.id}/${old.filter(p => p.id.startsWith(`${protocol.id}/`)).length + 1}`,
                                status: false,
                                responsibility: "ALLE",
                                descriptions: [],
                                attachments: []
                            }]));
                            setExpanded([expand]);
                            setTimestamp(Date.now());
                        }
                    }
                >
                    Mangel
                </Button>
            </HStack>
        </Container>
        </>
    )
}