import { As, Box, Button, ButtonGroup, ButtonProps, Container, useColorModeValue, BoxProps, Divider, Textarea ,Flex, Heading, useColorModeValue as mode, HStack, Icon, IconButton, IconButtonProps, Input, Stack, Text, useEditableControls, VStack, StackDivider, InputGroup, InputLeftElement, useBreakpointValue, Badge, Table, Tbody, Td, Th, Thead, Tr, ListItem, List, Square, UnorderedList, EditableInput, Editable, EditablePreview, ListIcon, useEditableState, SimpleGrid, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, Tag, TagLeftIcon, DrawerCloseButton, Portal, AccordionPanel, AccordionItem, Accordion, AccordionButton, AccordionIcon, PinInputField, PinInput, TableCaption, TableContainer, Tfoot, Checkbox, Switch } from '@chakra-ui/react'
import * as React from 'react'
import { useState } from 'react';
import { FiCheck, FiEye } from 'react-icons/fi';
import { IApiBauKGProtocol } from '../../../../api/models/IApiBauKGProtocol';
import { RadioButton, RadioButtonGroup } from '../../../cui-pro/RadioButtonGroup/RadioButtonGroup';

export interface IEditProtocolSummaryProps {
    protocol: IApiBauKGProtocol;
    setDanger: React.Dispatch<React.SetStateAction<boolean | null>>;
    setDeadline: React.Dispatch<React.SetStateAction<string>>;
    setResend: React.Dispatch<React.SetStateAction<boolean | null>>;
    onSubmit(): void;
    onPreview(): void;
}

const protocolDateToDisplayDate = (protocolDate: string): string => {
    var parts = protocolDate.split('-');
    return `${parts[2]}.${parts[1]}.${parts[0]}`;
}

export const EditProtocolSummary = ({ protocol, onSubmit, setDanger, setDeadline, setResend, onPreview }: IEditProtocolSummaryProps ) => {

    const [submitEnabled, setSubmitEnabled] = useState(false);

    var checklistStatistics = [0, 0, 0];
    Object.getOwnPropertyNames(protocol.checklist).forEach(topic => {
        Object.getOwnPropertyNames(protocol.checklist[topic]).forEach(entry => {
            switch(protocol.checklist[topic][entry]) {
                case false: { checklistStatistics[0]++; break; }
                case true:  { checklistStatistics[1]++; break; }
                case null:  { checklistStatistics[2]++; break; }
            }
        });
    });

    return (
        <VStack spacing={4} px={2} py={4} display="block" overflow="auto">
            <VStack w="full" px={2}>
                <Text style={{ 
                        fontFamily: "var(--chakra-fonts-heading)", 
                        fontWeight: "var(--chakra-fontWeights-medium)",
                        fontSize: "var(--chakra-fontSizes-sm)"
                    }}>
                    Begehungsprotokoll vom</Text>
                <Text><b>{protocolDateToDisplayDate(protocol.date)} ({protocol.time} Uhr)</b></Text>
            </VStack>
            <Divider />
            <TableContainer w="100%" px={2}>
                <Table variant='simple' size='sm' textAlign='center'>
                    <TableCaption placement='top' fontSize="sm" mt={0}>Sicherheitscheckliste</TableCaption>
                    <Thead>
                        <Tr>
                            <Th w="33%" textAlign='center'>nicht<br/>erfüllt</Th>
                            <Th w="33%" textAlign='center'>erfüllt</Th>
                            <Th w="33%" textAlign='center'>nicht<br/>zutreffend</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            {checklistStatistics.map(value => (<Td textAlign='center'><b>{value}</b></Td>))}
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
            <TableContainer w="100%" px={2}>
                <Table variant='simple' size='sm' textAlign='center'>
                    <TableCaption placement='top' fontSize="sm" mt={0}>Mängel</TableCaption>
                    <Thead>
                        <Tr>
                            <Th w="50%" textAlign='center'>offen</Th>
                            <Th w="50%" textAlign='center'>erledigt</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td textAlign='center'><b>{protocol.deficits.filter(d => d.status === false).length}</b></Td>
                            <Td textAlign='center'><b>{protocol.deficits.filter(d => d.status === true).length}</b></Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
            <VStack width="full">
                <Text fontFamily="heading" fontSize="sm" color="gray.600" fontWeight="medium" px={4} py={2}>Gefahr im Verzug</Text>
                <RadioButtonGroup key={"danger"} defaultValue={`${protocol.danger}`} size={'md'} display="flex" width="full"
                                  onChange={e => {
                                    switch(e) {
                                        case "false": { setDanger(false); break; }
                                        case "true": { setDanger(true); break; }
                                    }
                                  }}
                >
                    <RadioButton value="false" _checked={{ bg: 'green.200' }} grow={true} >nein</RadioButton>
                    <RadioButton value="true" _checked={{ bg: 'red.200' }} grow={true} >ja</RadioButton>
                </RadioButtonGroup> 
            </VStack>
            <VStack width="full">
                <Text fontFamily="heading" fontSize="sm" color="gray.600" fontWeight="medium" px={4} py={2}>Mängel zu beseitigen bis</Text>
                <RadioButtonGroup key={"fixUntil"} defaultValue={protocol.deadline === "sofort" ? "sofort" : "date"} size={'md'} display="flex" width="full"
                                  onChange={e => {
                                    switch(e) {
                                        case "sofort": { 
                                            setDeadline("sofort"); 
                                            break; 
                                        }
                                        case "date": { 
                                            var date = new Date();
                                            setDeadline(date.toISOString().substring(0, 10));
                                            break; 
                                        }
                                    }
                                  }}
                >
                    <RadioButton value="sofort" grow={true}>Sofort</RadioButton>
                    <RadioButton value="date" grow={true}>Datum</RadioButton>
                </RadioButtonGroup> 
                {(protocol.deadline !== "sofort") && (
                    <Input type="date" defaultValue={protocol.deadline === "sofort" ? "sofort" : protocol.deadline} textAlign="center" onChange={e => setDeadline(e.currentTarget.valueAsDate!.toISOString().substring(0, 10))} />
                )}
            </VStack>
            <VStack width="full">
                <Text fontFamily="heading" fontSize="sm" color="gray.600" fontWeight="medium" px={4} py={2}>BauKG Dokumente mitsenden</Text>
                <RadioButtonGroup key={"resend"} defaultValue={`${protocol.resend}`} size={'md'} display="flex" width="full"
                                  onChange={e => {
                                    switch(e) {
                                        case "false": { setResend(false); break; }
                                        case "true": { setResend(true); break; }
                                    }
                                  }}
                >
                    <RadioButton value="false" grow={true}>nein</RadioButton>
                    <RadioButton value="true" grow={true}>ja</RadioButton>
                </RadioButtonGroup> 
            </VStack>
            <Divider />
            <FormControl display='flex' alignItems='center' mt="6 !important">
                <Switch id="enable-submit" size="lg" ml={2} mr={4} defaultChecked={submitEnabled} onChange={e => setSubmitEnabled(e.currentTarget.checked)} />
                <FormLabel htmlFor='enable-submit' mb='0' fontSize="md">
                    Protokoll vollständig und bereit zur Veröffentlichung
                </FormLabel>
            </FormControl>
            <HStack>
                <Button variant="primary" mt="2 !important" px={2} size="lg" flexGrow={2} 
                    disabled={!submitEnabled}
                    leftIcon={<FiCheck />}
                    onClick={onSubmit}
                    >
                        Protokoll abschließen
                </Button>
                <Button variant="outline" mt="2 !important" px={2} size="lg" flexGrow={1} 
                    aria-label="preview"
                    leftIcon={<FiEye />}
                    onClick={onPreview}
                    >
                        Vorschau
                </Button>
            </HStack>
        </VStack>
    );
}