import * as React from "react"
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  Button,
  extendTheme,
  Container,
  Center,
  Spinner,
  Portal,
  Heading,
} from "@chakra-ui/react";
import { theme } from '@chakra-ui/pro-theme';
import { ColorModeSwitcher } from "./ColorModeSwitcher";

import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";

import '@fontsource/inter/variable.css'

import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate, 
  Navigate,
  useParams
} from "react-router-dom";
import { ShellWithSidebar } from "./components/cui-pro/ShellWithSidebar/ShellWithSidebar";
import { FiDownloadCloud } from "react-icons/fi";
import { NavbarItems } from "./components/cui-pro/ShellWithSidebar/NavbarItems";
import { useState, useEffect } from "react";
import { InteractionStatus, PublicClientApplication } from "@azure/msal-browser";
import { ProjectDetailsWrapper } from "./components/custom/project/ProjectDetailsWrapper";
import { Projects } from "./components/custom/projects/Projects";
import { IApiProject, IApiProjectBauKGCoreData } from "./api/models/IApiProject";
import { ProjectCoreData } from "./components/custom/project/coredata/ProjectCoreData";
import { ProjectParties } from "./components/custom/project/parties/ProjectParties";
import API from "./api/API";
import toast, { Toaster } from 'react-hot-toast';
import { ProjectSafetyChecklist } from "./components/custom/project/safetychecklist/ProjectSafetyChecklist";
import { ProjectDocuments } from "./components/custom/project/documents/ProjectDocuments";
import { IApiBauKGDocument } from "./api/models/IApiBauKGDocument";
import { ProjectOverview } from "./components/custom/project/overview/ProjectOverview";
import { Settings } from "./components/custom/settings/Settings";
import { IApiBauKGChecklistTemplate } from "./api/models/IApiBauKGChecklistTemplate";
import { ProjectProtocols } from "./components/custom/project/protocols/ProjectProtocols";
import { IApiBauKGProtocol } from "./api/models/IApiBauKGProtocol";
import { ProjectContacts } from "./components/custom/project/contacts/ProjectContacts";
import { Logo } from "./components/cui-pro/ShellWithSidebar/Logo";

const pages = [
  {
    name: "Projekte",
    path: "projects",
  },
  {
    name: "Einstellungen",
    path: "settings",
  },
  {
    name: "Übersicht",
    path: "",
  },
  {
    name: "Protokolle",
    path: "protocols",
  },
  {
    name: "Kontakte",
    path: "contacts",
  },
  {
    name: "Stammdaten",
    path: "core",
  },
  {
    name: "Beteiligtenliste",
    path: "participants",
  },
  {
    name: "Dokumente",
    path: "documents",
  },
  {
    name: "Sicherheitscheckliste",
    path: "checklist",
  },
];

export const App = () => {

  const [selectedPage, setSelectedPage] = useState(NavbarItems.Projects);  
  const [pageName, setPageName] = useState(pages[NavbarItems.Projects].name);
  const [companyName, setCompanyName] = useState("Ferro & Partner ZT GmbH");

  const [projects, setProjects] = useState<IApiProject[]>([]);

  const [selected, setSelected] = useState<IApiProject>({} as IApiProject);
  const [documents, setDocuments] = useState<IApiBauKGDocument[]>([]);

  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = accounts?.length > 0 ? true : false;

  const navigate = useNavigate();
  const { projectId } = useParams();

  const myTheme = extendTheme(
    {
      colors: { ...theme.colors, brand: theme.colors.red },
    },
    theme,
  );

  useEffect(() => {
    if(isAuthenticated && (API.instance !== instance || API.account !== accounts[0]) && inProgress === InteractionStatus.None) {
      API.instance = instance;
      API.account = accounts[0];
      API.getProjects().then(result => setProjects(result));
      /*
      var accessTokenRequest = {
        scopes: ["bb2e4708-3c13-4dc1-aaff-1fba0fa6b10b/user_impersonation"], // baukg.app API!
        account: accounts[0],
      };
      instance.acquireTokenSilent(accessTokenRequest)
      .then(accessTokenResponse => {
        API.token = accessTokenResponse.accessToken;
        API.getProjects().then(result => setProjects(result));
      })
      .catch(error => {
        instance.acquireTokenRedirect(accessTokenRequest).then(result => {
          instance.acquireTokenSilent(accessTokenRequest)
          .then(accessTokenResponse => {
            API.token = accessTokenResponse.accessToken;
            API.getProjects().then(result => setProjects(result));
          })
        })
        console.log(error);
      });
      */
    }
  }, [isAuthenticated, accounts, inProgress, instance]);

  useEffect(() => {
    if(selected && selected.id && selected.id.length > 0) {
      API.getDocuments(selected.id).then(result => setDocuments(result));
    }
  }, [selected]);

  useEffect(() => {
    // quick & dirty fix -> unmount of protocol also happens on page changes -> selected is updated and not cleared in some cases..
    if(selectedPage < NavbarItems.ProjectOverview) {
      setSelected({} as IApiProject);
    }
  }, [selectedPage])

  const updateSelected = (updatedProject: IApiProject) => {
    var index = projects.indexOf(selected);
    var updatedProjects = [...projects];
    updatedProjects.splice(index, 1, updatedProject);
    setProjects(updatedProjects);
    setSelected(updatedProject);
  };

  if(inProgress === InteractionStatus.None && !isAuthenticated) {
    instance.loginRedirect(loginRequest).catch(e => {
      console.error(e);
    });
  }
  
  if(!isAuthenticated || (API.instance !== instance || API.account !== accounts[0])) {
    return (
      <ChakraProvider theme={myTheme}>
        <Center h='100vh' w='100vw' flexDir="column">
          <Logo />
          <Spinner mt={8} color='red' size='xl' />
        </Center>  
      </ChakraProvider>
    );
  }

  return (
      <ChakraProvider theme={myTheme}>
          <Toaster 
            position="bottom-right"
            gutter={8}
            toastOptions={{
              style: {
                fontSize: 'var(--chakra-fontSizes-sm)',
                //marginRight: 'var(--chakra-space-6)',
                //marginBottom: 'var(--chakra-space-6)'
              },
            }}
          />
          <ShellWithSidebar 
            pageName={pageName} 
            companyName={companyName} 
            projectName={selected.name}
            user={{
              email: accounts[0]?.username,
              name: accounts[0]?.name,
              image: undefined
            }}
            selectedPage={selectedPage}
            onLogout={() => instance.logoutRedirect()}
            onPageSelected={(page: NavbarItems) => {
              setSelectedPage(page);
              setPageName(pages[page].name);
              if(page >= NavbarItems.ProjectOverview) {
                navigate(`/projects/${selected.id}/${pages[page].path}`);
              } else {
                navigate(pages[page].path);
                setSelected({} as IApiProject); // clear project selection when a 'global' page is selected
              }
            }}            
          >
                <Routes>
                  <Route path={pages[NavbarItems.Projects].path}  
                         element={
                          <Projects
                            projects={projects}
                            onProjectSelected={selection => {
                              setSelected(selection);
                              setSelectedPage(NavbarItems.ProjectOverview);
                              setPageName(pages[NavbarItems.ProjectOverview].name);
                              navigate(`/projects/${selection.id}`);
                            }} 
                            onActivateProject={(id: string) => {
                              API.activateProject(id).then(result => {
                                setProjects(oldProjects => {
                                    var newProjects = [...oldProjects];
                                    var index = newProjects.findIndex(p => p.id === result.id);
                                    newProjects.splice(index, 1, result);
                                    return newProjects;
                                });
                              });
                            }}
                            onReloadProjects={() => {
                              API.getProjects().then(result => {
                                setProjects(result)
                                toast.success("Aktualisierung erfolgreich!\n(Projekte)");
                              });
                            }}
                            onShowProtocols={selection => {
                              setSelected(selection);
                              setSelectedPage(NavbarItems.ProjectProtocols);
                              setPageName(pages[NavbarItems.ProjectProtocols].name);
                              navigate(`/projects/${selection.id}/${pages[NavbarItems.ProjectProtocols].path}`);
                            }} 
                          />
                        } 
                  />
                  <Route path={pages[NavbarItems.Settings].path}
                         element={
                          <Settings 
                          />
                         } />
                  <Route path="/projects/:projectId"  
                         element={<ProjectDetailsWrapper
                            projects={projects}
                            selected={selected}
                            setSelected={setSelected}
                         />}>
                    <Route path={pages[NavbarItems.ProjectOverview].path} 
                           element={
                              <ProjectOverview 
                                project={selected}
                                documents={documents}
                                onSelectProtocols={() => {
                                  setSelectedPage(NavbarItems.ProjectProtocols);
                                  setPageName(pages[NavbarItems.ProjectProtocols].name);
                                  navigate(`/projects/${selected.id}/${pages[NavbarItems.ProjectProtocols].path}`);
                                }}
                                onSelectParties={() => {
                                  setSelectedPage(NavbarItems.ProjectParties);
                                  setPageName(pages[NavbarItems.ProjectParties].name);
                                  navigate(`/projects/${selected.id}/${pages[NavbarItems.ProjectParties].path}`);
                                }}
                                onSelectDocuments={() => {
                                  setSelectedPage(NavbarItems.ProjectDocuments);
                                  setPageName(pages[NavbarItems.ProjectDocuments].name);
                                  navigate(`/projects/${selected.id}/${pages[NavbarItems.ProjectDocuments].path}`);
                                }}
                                onSelectCoreData={() => {
                                  setSelectedPage(NavbarItems.ProjectCoreData);
                                  setPageName(pages[NavbarItems.ProjectCoreData].name);
                                  navigate(`/projects/${selected.id}/${pages[NavbarItems.ProjectCoreData].path}`);
                                }}
                              />
                           } />
                    <Route path={pages[NavbarItems.ProjectProtocols].path} 
                           element={
                              <ProjectProtocols 
                                project={selected}
                                onPreviewProtocol={(projectId: string, protocolId: string) => {
                                  var downloadPromise = API.getProtocolPreview(projectId, protocolId);
                                  toast.promise(downloadPromise, {
                                    loading: 'Vorschau wird geladen...',
                                    success: 'Vorschau erfolgreich erstellt!',
                                    error: 'Fehler beim Erstellen der Vorschau!',
                                  });
                                  downloadPromise.then(blob => {
                                    // https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
                                    const url = window.URL.createObjectURL(blob);
                                    window.open(url, "_blank");
                                    setTimeout(function(){
                                      // For Firefox it is necessary to delay revoking the ObjectURL
                                      window.URL.revokeObjectURL(url);
                                    }, 100);
                                  });
                                }}
                                onCreateProtocol={(project: IApiProject) => {
                                  API.createProtocol(project.id).then(result => {
                                    updateSelected(result);
                                    toast.success(`Protokoll erfolgreich erstellt!`);
                                  });
                                }}
                                onDeleteProtocol={(protocol: IApiBauKGProtocol) => {
                                  API.deleteProtocol(selected.id, protocol.id).then(result => {
                                    updateSelected(result);
                                    toast.success(`Protokoll erfolgreich gelöscht!`);
                                  });
                                }}
                                onUnmount={(updatedProtocols: IApiBauKGProtocol[]) => {
                                  updateSelected({
                                    ...selected,
                                    baukg: {
                                      ...selected.baukg,
                                      protocols: [...updatedProtocols]
                                    }
                                  });
                                }}
                              />
                           } 
                    />
                    <Route path={pages[NavbarItems.ProjectContacts].path} 
                           element={
                              <ProjectContacts 
                                project={selected}
                              />
                            } 
                    />
                    <Route path={pages[NavbarItems.ProjectCoreData].path} 
                           element={
                              <ProjectCoreData 
                                project={selected} 
                                onSave={(data: IApiProjectBauKGCoreData) => {
                                  API.updateCoreData(selected.id, {
                                    ...data,
                                    beobachter: data.beobachter!.filter(b => b !== undefined) // filter empty elements
                                  }).then(updated => {
                                    updateSelected(updated);
                                    toast.success(`${updated.name}\nErfolgreich aktualisiert\n(Stammdaten)`);
                                  });
                                }}
                              />
                            } 
                    />
                    <Route path={pages[NavbarItems.ProjectParties].path}  
                           element={
                              <ProjectParties
                                project={selected} 
                                onCreate={(party, close) => {
                                  API.addParty(selected.id, party).then(updated => {
                                    updateSelected(updated);
                                    close();
                                    var name = party.contact && `${party.contact.name.length > 0 ? party.contact.name : `${party.contact.firstName} ${party.contact.lastName}`}`;
                                    toast.success(`${name}\nErfolgreich erstellt!`);
                                  });
                                }}
                                onUpdate={(party, property) => {
                                  API.updateParty(selected.id, party).then(updated => {
                                    updateSelected(updated);
                                    var name = party.contact && `${party.contact.name.length > 0 ? party.contact.name : `${party.contact.firstName} ${party.contact.lastName}`}`;
                                    toast.success(`${name}\nErfolgreich aktualisiert!\n(${property})`);
                                  });
                                }}
                                onDelete={(party) => {
                                  API.deleteParty(selected.id, party).then(updated => {
                                    updateSelected(updated);
                                    toast.success(`Erfolgreich gelöscht!`);
                                  });
                                }}
                              />
                           }
                    />
                    <Route path={pages[NavbarItems.ProjectDocuments].path}  
                           element={
                            <ProjectDocuments
                              documents={documents}
                              onAdd={(files) => {
                                API.addDocuments(selected.id, files).then(addedDocuments => {
                                  for(var doc of addedDocuments) {
                                    toast.success(`Upload erfolgreich!\n(${doc.name})`);
                                  }
                                  setDocuments(oldDocuments => ([...oldDocuments, ...addedDocuments]));
                                });
                              }}
                            />
                           }
                    />
                    <Route path={pages[NavbarItems.ProjectSafetyChecklist].path}
                           element={
                            <ProjectSafetyChecklist 
                              project={selected} 
                              onSave={(checklist: { [key: string]: string[] }) => {
                                API.updateSafetyChecklist(selected.id, checklist).then(updated => {
                                  updateSelected(updated);
                                  toast.success(`${updated.name}\nErfolgreich aktualisiert!\n(Sicherheitscheckliste)`);
                                });
                              }}
                              onGetTemplate={async () => (await API.getChecklistTemplate()).value}
                            />
                          }
                    />
                  </Route>
                  <Route
                    path="*"
                    element={<Navigate replace to="/projects" />}
                  />
                </Routes>
          </ShellWithSidebar>
      </ChakraProvider>
  )
}

/*

<div>
              <img src={user.picture} alt={user.name} />
              <h2>{user.name}</h2>
              <p>{user.email}</p>
              <Button onClick={() => logout({ returnTo: window.location.origin })}>Logout</Button>
            </div>




    <ChakraProvider theme={theme}>
        <Box textAlign="center" fontSize="xl">
          <Grid minH="100vh" p={3}>
            <ColorModeSwitcher justifySelf="flex-end" />
            <VStack spacing={8}>
              <Text>
                Edit <Code fontSize="xl">src/App.tsx</Code> and save to reload.
              </Text>
              <Link
                color="teal.500"
                href="https://chakra-ui.com"
                fontSize="2xl"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn Chakra
              </Link>
            </VStack>
          </Grid>
        </Box>
      </ChakraProvider>

          <AuthenticatedTemplate>
              <div className="App">
                  <header className="App-header">
                      <p>
                          Hello {accounts[0]?.idTokenClaims.given_name}!
                      </p>

                      <button onClick={() => instance.logoutRedirect({ postLogoutRedirectUri: "/" })}>
                          Logout
                      </button>
                  </header>
              </div>
          </AuthenticatedTemplate>



*/