export enum NavbarItems {
    Projects,
    Settings,

    ProjectOverview,
    ProjectProtocols,
    ProjectContacts,
    ProjectCoreData,
    ProjectParties,
    ProjectDocuments,
    ProjectSafetyChecklist,
}