import { As, Box, Button, ButtonGroup, ButtonProps, Container, useColorModeValue, useColorModeValue as mode, BoxProps, Divider, Textarea ,Flex, Heading, HStack, Icon, IconButton, IconButtonProps, Input, Stack, Text, useEditableControls, VStack, StackDivider, InputGroup, InputLeftElement, useBreakpointValue, Badge, Table, Tbody, Td, Th, Thead, Tr, Tooltip, Checkbox, Editable, EditableInput, EditablePreview, Switch, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, PopoverFooter, SimpleGrid, Grid, GridItem, List, ListItem, ResponsiveValue, Tag, TagLeftIcon } from '@chakra-ui/react'
import * as React from 'react'
import { useState } from 'react';
import { IProjectPartiesProps } from './IProjectParties';
import { FiCheck, FiMail, FiPlay, FiPlus, FiSquare, FiTrash2, FiX } from 'react-icons/fi';
import { AddPartyDrawer } from '../../drawers/AddPartyDrawer/AddPartyDrawer';
import { RadioIconButtonGroup, RadioIconButton } from '../../../cui-pro/RadioIconButtonGroup/RadioIconButtonGroup';
import { IApiProjectBauKGParty } from '../../../../api/models/IApiProject';
import { ShellContentContainer } from '../../../cui-pro/ShellWithSidebar/ShellContentContainer';
import { DeleteConfirmationPopover } from '../../deleteconfirmationpopover/DeleteConfirmationPopover';
import * as CSS from "csstype";
import Helpers from '../../../../helpers/Helpers';

interface IProjectPartyDocumentStatusProps {
    defaultValue: string;
    onChange(value: string): void;
    isDisabled: boolean;
}

const ProjectPartyDocumentStatus = (props: IProjectPartyDocumentStatusProps) => {

    const radioButtonProps = {
        w: "24px",
        h: "24px",
        minW: "24px",
        maxW: "24px",
        minH: "24px",
        maxH: "24px"
    };

    return (
        <RadioIconButtonGroup key="aba" defaultValue={props.defaultValue} size="md" onChange={props.onChange} isDisabled={props.isDisabled}>
            <RadioIconButton value="yes" aria-label="yes" {...radioButtonProps} icon={<FiCheck fontSize="0.75rem" />}
                             _checked={{
                                 bg: 'green.200'
                             }}
            />
            <RadioIconButton value="no" aria-label="no" {...radioButtonProps} icon={<FiX fontSize="0.75rem" />}
                             _checked={{
                                bg: 'red.200'
                             }} 
            
            />
            <RadioIconButton value="ignore" aria-label="ignore" {...radioButtonProps} icon={<FiSquare fontSize="0.75rem" />} />
        </RadioIconButtonGroup>
    );

}

export const ProjectParties = (props: IProjectPartiesProps) => {

    const [parties, setParties] = useState<IApiProjectBauKGParty[]>([ ...props.project.baukg.parties ]);
    const [showAddParty, setShowAddParty] = useState(false);

    const checkboxColW = "3%";
    const sliderColW = "3%";

    const isDesktop = useBreakpointValue({ base: false, lg: true });
    const showTable = useBreakpointValue({ base: false, xl: true });

    const tdProps = {
        //paddingLeft: "12px",
        //paddingRight: "12px"
    };

    const mobileDocStatusProps = {
        color: "gray.600", 
        textAlign: { base: "left", md: "center"} as (ResponsiveValue<CSS.Property.TextAlign> | undefined),
        fontWeight: "medium",
        fontSize: { base: "sm", md: "md" } as (ResponsiveValue<CSS.Property.FontSize> | undefined)
    };

    React.useEffect(() => {
        setParties(props.project.baukg.parties);
    }, [props.project]);

    const AddPartyButton = () => (isDesktop ? 
        (<Button leftIcon={<FiPlus />} variant='primary' onClick={() => setShowAddParty(true)}>Hinzufügen</Button>)
        :
        (<IconButton variant="primary" icon={<FiPlus />} aria-label='Hinzufügen' onClick={() => setShowAddParty(true)} />)
    );

    return (
        <ShellContentContainer
            leftButton={undefined}
            rightButton={<AddPartyButton />}
            buttonsInTitleRow={true}
            justifyButtons={"end"}
        >
            <Container p={{ base: 0, md: 0 }} mx={0}>
                <Box
                    bg="bg-surface"
                    boxShadow={{ base: 'none', md: useColorModeValue('sm', 'sm-dark') }}
                    borderRadius={useBreakpointValue({ base: 'none', md: 'lg' })}
                >
                        {(showTable && (
                            <Table>
                                <Thead>
                                    <Tr>
                                        <Th {...tdProps} maxW={sliderColW}>Aktiv</Th>
                                        <Th {...tdProps} w="32%">Firma</Th>
                                        <Th {...tdProps} w="28%">Gewerk</Th>
                                        <Th {...tdProps} w="28%">Arbeitsbeginn</Th>
                                        <Th {...tdProps} textAlign='center' maxW={checkboxColW}><Tooltip label='Arbeitsbeginnanzeige' fontSize='sm'>ABA</Tooltip></Th>
                                        <Th {...tdProps} textAlign='center' maxW={checkboxColW}><Tooltip label='Unterweisungsnachweis' fontSize='sm'>UWN</Tooltip></Th>
                                        <Th {...tdProps} textAlign='center' maxW={checkboxColW}><Tooltip label='Baustellenevaluierung' fontSize='sm'>BEV</Tooltip></Th>
                                        <Th {...tdProps} ></Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                {parties.map((party) => (
                                    <Tr key={party.contact?.mail}>
                                        <Td {...tdProps} maxW={sliderColW}>
                                            <Switch size='sm' isChecked={party.active} onChange={(e) => {
                                                props.onUpdate({
                                                    ...party,
                                                    active: e.currentTarget.checked
                                                }, "Aktiv");
                                            }} />
                                        </Td>
                                        <Td {...tdProps}>
                                            <VStack spacing="2" align="start" w="100%">
                                                <VStack spacing="0" align="start">
                                                    {party.contact && party.contact.name.length > 0 && (
                                                        <Text fontWeight="medium" fontSize="sm">
                                                            {party.contact.name}
                                                        </Text>
                                                    )}
                                                    {party.contact && party.contact.firstName.length > 0 && party.contact.lastName.length > 0 && (
                                                        <Text color="muted" fontSize="sm">
                                                            {`${party.contact.firstName} ${party.contact.lastName}`}
                                                        </Text>
                                                    )}
                                                </VStack>
                                                <Tag fontSize="xs" px="2">
                                                    <TagLeftIcon boxSize='12px' as={FiMail} />
                                                        {party.contact && party.contact.mail}
                                                </Tag>
                                            </VStack>
                                        </Td>
                                        <Td {...tdProps}>
                                            {party.trade?.value}
                                        </Td>
                                        <Td {...tdProps}>
                                            {Helpers.formatDate(party.startDate)}
                                        </Td>
                                        <Td {...tdProps} textAlign='center' maxW={checkboxColW} p={2}>
                                            <ProjectPartyDocumentStatus defaultValue={party.aba} isDisabled={!party.active} onChange={(value) => {
                                                props.onUpdate({
                                                    ...party,
                                                    aba: value
                                                }, "Arbeitsbeginnanzeige");
                                            }} />
                                        </Td>
                                        <Td {...tdProps} textAlign='center' maxW={checkboxColW} p={2}>
                                            <ProjectPartyDocumentStatus defaultValue={party.uwn} isDisabled={!party.active} onChange={(value) => {
                                                props.onUpdate({
                                                    ...party,
                                                    uwn: value
                                                }, "Unterweisungsnachweis");
                                            }} />
                                        </Td>
                                        <Td {...tdProps} textAlign='center' maxW={checkboxColW} p={2}>
                                            <ProjectPartyDocumentStatus defaultValue={party.bev} isDisabled={!party.active} onChange={(value) => {
                                                props.onUpdate({
                                                    ...party,
                                                    bev: value
                                                }, "Baustellenevaluierung");
                                            }} />
                                        </Td>
                                        <Td {...tdProps}>
                                            <DeleteConfirmationPopover
                                                header="Eintrag löschen?"
                                                body="Dieser Vorgang kann nicht rückgängig gemacht werden!"
                                                placement='left'
                                                trigger={
                                                    <IconButton aria-label='delete' icon={<FiTrash2 />} variant='ghost' color='red' />
                                                }
                                                onConfirm={() => props.onDelete(party)}
                                            />                                        
                                        </Td>
                                    </Tr>
                                ))}
                                </Tbody>
                            </Table>
                        )) || (
                            <List listStyleType="none" spacing="4">
                            {parties.map((party) => (
                                <ListItem
                                    key={party.contact?.mail}
                                    value={party.contact?.mail}
                                    bg="bg-surface"
                                    p="4"
                                    my="2"
                                    mx="2"
                                    boxShadow={mode('sm', 'sm-dark')}
                                    position="relative"
                                    borderRadius="lg"
                                >
                                    <VStack spacing="4">
                                        <HStack spacing={6} width="full" justifyContent="start" alignItems="center">
                                            <Switch size={{ base: "sm", md: "md" }} 
                                                isChecked={party.active} 
                                                onChange={(e) => {
                                                    props.onUpdate({
                                                        ...party,
                                                        active: e.currentTarget.checked
                                                    }, "Aktiv");
                                                }}
                                            />
                                            <VStack spacing="2" align="start">
                                                <VStack spacing="1" align="start">
                                                    {party.contact && party.contact.name.length > 0 && (
                                                        <Text fontWeight="medium" fontSize={{ base: "sm", md: "md" }}>
                                                            {party.contact.name}
                                                        </Text>
                                                    )}
                                                    {party.contact && party.contact.firstName.length > 0 && party.contact.lastName.length > 0 && (
                                                        <Text color="muted" fontSize="sm">
                                                            {`${party.contact.firstName} ${party.contact.lastName}`}
                                                        </Text>
                                                    )}
                                                </VStack>
                                                <HStack>
                                                    <Badge colorScheme={'gray'} size={{ base: "xs", sm: "sm" }}>
                                                        {`Gewerk: ${party.trade?.value}`}
                                                    </Badge>
                                                    <Badge colorScheme={'gray'} size={{ base: "xs", sm: "sm" }}>
                                                        {`Arbeitsbeginn: ${Helpers.formatDate(party.startDate)}`}
                                                    </Badge>
                                                </HStack>
                                            </VStack>
                                        </HStack>
                                        <Divider />
                                        <HStack width="full" justifyContent="space-around">
                                            <VStack>
                                                <Text {...mobileDocStatusProps}>
                                                    <Tooltip label='Arbeitsbeginnanzeige' fontSize='sm'>ABA</Tooltip>
                                                </Text>
                                                <ProjectPartyDocumentStatus 
                                                    defaultValue={party.aba} 
                                                    isDisabled={!party.active} 
                                                    onChange={(value) => {
                                                        props.onUpdate({
                                                            ...party,
                                                            aba: value
                                                        }, "Arbeitsbeginnanzeige");
                                                    }} 
                                                />
                                            </VStack>
                                            <VStack>
                                                <Text {...mobileDocStatusProps}>
                                                    <Tooltip label='Unterweisungsnachweis' fontSize='sm'>UWN</Tooltip>
                                                </Text>
                                                <ProjectPartyDocumentStatus 
                                                    defaultValue={party.uwn} 
                                                    isDisabled={!party.active} 
                                                    onChange={(value) => {
                                                        props.onUpdate({
                                                            ...party,
                                                            uwn: value
                                                        }, "Unterweisungsnachweis");
                                                    }} 
                                                />
                                            </VStack>
                                            <VStack>
                                                <Text {...mobileDocStatusProps}>
                                                    <Tooltip label='Baustellenevaluierung' fontSize='sm'>BEV</Tooltip>
                                                </Text>
                                                <ProjectPartyDocumentStatus 
                                                    defaultValue={party.bev} 
                                                    isDisabled={!party.active} 
                                                    onChange={(value) => {
                                                        props.onUpdate({
                                                            ...party,
                                                            bev: value
                                                        }, "Baustellenevaluierung");
                                                    }} 
                                                />
                                            </VStack>
                                        </HStack>
                                    </VStack>
                                </ListItem>
                            ))}
                            </List>
                        )}
                </Box>
                <AddPartyDrawer isOpen={showAddParty} 
                                onDiscard={() => setShowAddParty(false)} 
                                onSubmit={(party: IApiProjectBauKGParty) => props.onCreate(party, () => setShowAddParty(false))} 
                            />
            </Container>
        </ShellContentContainer>
    );

}