import { As, Button, ButtonProps, Center, HStack, Icon, Spinner, Text } from '@chakra-ui/react'
import * as React from 'react'

import { Outlet, useNavigate, useParams } from "react-router-dom";
import { IApiProject } from '../../../api/models/IApiProject';

export const ProjectDetailsWrapper = ({ 
  projects, selected, setSelected
}: { 
  projects: IApiProject[],
  selected: IApiProject,
  setSelected(value: React.SetStateAction<IApiProject>): void;
}) => {

  const navigate = useNavigate();
  let { projectId } = useParams();

  if(projectId && !selected.id) {
    var index = projects.findIndex(p => p.id === projectId);
    if(index >= 0) {
      setSelected(projects[index]);
    } else {
      navigate(`/`);
    }
    return (
      <Center h='100vh' w='100vw'>
        <Spinner color='red' size='xl' />
      </Center>  
    );
  }

  return (
    <Outlet />
  );
}
