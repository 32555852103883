import { As, Box, Button, ButtonGroup, ButtonProps, Container, useColorModeValue, BoxProps, Divider, Textarea ,Flex, Heading, useColorModeValue as mode, HStack, Icon, IconButton, IconButtonProps, Input, Stack, Text, useEditableControls, VStack, StackDivider, InputGroup, InputLeftElement, useBreakpointValue, Badge, Table, Tbody, Td, Th, Thead, Tr, ListItem, List, Square, UnorderedList, EditableInput, Editable, EditablePreview, ListIcon, useEditableState, SimpleGrid } from '@chakra-ui/react'
import * as React from 'react'

  import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
  } from '@chakra-ui/react'

import { useState, useRef } from 'react';
import { FiArrowDown, FiArrowUp, FiCheck, FiDelete, FiEdit2, FiFile, FiFileText, FiLink, FiLink2, FiPlus, FiTrash, FiTrash2 } from 'react-icons/fi';
import { ShellContentContainer } from '../../../cui-pro/ShellWithSidebar/ShellContentContainer';
import { IApiBauKGDocument } from '../../../../api/models/IApiBauKGDocument';
import API from '../../../../api/API';
import { IProjectOverviewProps } from './IProjectOverview';
import { Stat } from '../../../cui-pro/Stat/Stat';




export const ProjectOverview = (props: IProjectOverviewProps ) => {

    return (
        <ShellContentContainer
            leftButton={undefined}
            rightButton={undefined}
        >
            <Container p={{ base: 0, md: 0 }} mx={0} w='100%' maxW='unset'>
                <Box as="section" py={{ base: '2', md: '4' }} pt={{ base: '2', md: '4' }}>
                    <Container px={{ base: 3, md: undefined }}>
                        <SimpleGrid columns={{ base: 1, md: 1 }} gap={{ base: '5', md: '6' }}>
                            <Box onClick={() => props.onSelectCoreData()} cursor="pointer">
                                <Stat key={'1'} label={'Bauvorhaben'} value={props.project.baukg.coredata.bauvorhaben} />
                            </Box>
                        </SimpleGrid>
                    </Container>
                </Box>
                <Box as="section" py={{ base: '2', md: '4' }} pb={{ base: '4', md: '8' }}>
                    <Container px={{ base: 3, md: undefined }}>
                        <SimpleGrid columns={{ base: 1, md: 3 }} gap={{ base: '5', md: '6' }}>
                            <Box onClick={() => props.onSelectProtocols()} cursor="pointer">
                                <Stat key={'1'} 
                                    label={'Protokolle'} 
                                    value={props.project.baukg.protocols.length.toString()} 
                                />
                            </Box>
                            <Box onClick={() => props.onSelectParties()} cursor="pointer">
                                <Stat key={'2'} 
                                    label={'Beteiligte'} 
                                    value={props.project.baukg.parties.length.toString()}
                                />
                            </Box>
                            <Box onClick={() => props.onSelectDocuments()} cursor="pointer">
                                <Stat key={'3'} 
                                    label={'Dokumente'} 
                                    value={props.documents.length.toString()}
                                />  
                            </Box>
                        </SimpleGrid>
                    </Container>
                </Box>
                
            </Container>
        </ShellContentContainer>
    );
}