import { As, Box, Button, ButtonGroup, ButtonProps, Container, useColorModeValue, BoxProps, Divider, Textarea ,Flex, Heading, useColorModeValue as mode, HStack, Icon, IconButton, IconButtonProps, Input, Stack, Text, useEditableControls, VStack, StackDivider, InputGroup, InputLeftElement, useBreakpointValue, Badge, Table, Tbody, Td, Th, Thead, Tr, ListItem, List, Square, UnorderedList, EditableInput, Editable, EditablePreview, ListIcon, useEditableState, SimpleGrid, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, Tag, TagLeftIcon, DrawerCloseButton, Portal, AccordionPanel, AccordionItem, Accordion, AccordionButton, AccordionIcon, PinInputField, PinInput } from '@chakra-ui/react'
import * as React from 'react'
import { useState } from 'react';
import { FiEdit2, FiMail, FiCheck, FiX, FiSquare, FiEyeOff } from 'react-icons/fi';
import API from '../../../../api/API';
import { IApiBauKGProtocol } from '../../../../api/models/IApiBauKGProtocol';
import { RadioIconButtonGroup, RadioIconButton } from '../../../cui-pro/RadioIconButtonGroup/RadioIconButtonGroup';
import { ShellContentContainer } from '../../../cui-pro/ShellWithSidebar/ShellContentContainer';
import { StepsWithAccent } from '../../../cui-pro/StepsWithAccent/StepsWithAccent';
import { Step } from '../../../cui-pro/StepsWithCircles/Step';
import { useStep } from '../../../cui-pro/StepsWithCircles/useStep';
import { StepsWithCirclesAndText } from '../../../cui-pro/StepsWithCirclesAndText/StepsWithCirclesAndText';
import { AutoResizeTextarea } from './AutoSizeTextarea';
import { IEditProtocolDrawerProps } from './IEditProtocolDrawer';

export interface IEditProtocolGeneralProps {
    protocol: IApiBauKGProtocol;
    setDate: React.Dispatch<React.SetStateAction<string>>;
    setTime: React.Dispatch<React.SetStateAction<string>>;
    setNotes: React.Dispatch<React.SetStateAction<string>>;
}

export const EditProtocolGeneral = ({ protocol, setDate, setTime, setNotes }: IEditProtocolGeneralProps ) => {

    return (
        <VStack spacing={6} px={4} py={8}>
            <FormControl>
                <FormLabel htmlFor='date' 
                    style={{ fontSize: '16px' }}
                >
                    Datum
                </FormLabel>
                <Input id="date" type="date" size="lg" textAlign="center" defaultValue={protocol.date} 
                       onChange={e => setDate(e.currentTarget.value)}
                />
            </FormControl>
            <FormControl>
                <FormLabel htmlFor='time' 
                    style={{ fontSize: '16px' }}
                >
                    Uhrzeit
                </FormLabel>
                <Input id="time" type="time" size="lg" textAlign="center" defaultValue={protocol.time} 
                       onChange={e => setTime(e.currentTarget.value)}
                />
            </FormControl>
            <Divider />
            <FormControl>
                <FormLabel htmlFor='notes' 
                    style={{ fontSize: '16px' }}
                >
                    Notizen
                </FormLabel>
                <AutoResizeTextarea size="sm" defaultValue={protocol.notes}
                                    onBlur={e => setNotes(e.currentTarget.value)}
                
                />
            </FormControl>
        </VStack>
    );
}