import { Box, Container, HStack, Stack } from '@chakra-ui/react'
import * as React from 'react'
import { IStepsWithAccent } from './IStepsWithAccent'
import { Step } from './Step'
import { useStep } from './useStep'

export const StepsWithAccent = (props: IStepsWithAccent) => {
  const { currentStep, setStep, steps } = props;
  return (
    <Box bg="bg-surface">
      <Container py={'2'}>
        <HStack spacing="4">
          {steps.map((step, id) => (
            <Step
              key={id}
              cursor="pointer"
              onClick={() => setStep(id)}
              title={step.title}
              description={step.description}
              isActive={currentStep === id}
              isCompleted={currentStep > id}
            />
          ))}
        </HStack>
      </Container>
    </Box>
  )
}
