import { As, Box, Button, ButtonGroup, ButtonProps, Container, useColorModeValue, BoxProps, Divider, Textarea ,Flex, Heading, useColorModeValue as mode, HStack, Icon, IconButton, IconButtonProps, Input, Stack, Text, useEditableControls, VStack, StackDivider, InputGroup, InputLeftElement, useBreakpointValue, Badge, Table, Tbody, Td, Th, Thead, Tr, ListItem, List, Square, UnorderedList, EditableInput, Editable, EditablePreview, ListIcon, useEditableState, SimpleGrid } from '@chakra-ui/react'
import * as React from 'react'
import { useState } from 'react';
import { FiDelete, FiEye, FiPlus, FiTrash2 } from 'react-icons/fi';
import API from '../../../../api/API';
import { IApiBauKGProtocol } from '../../../../api/models/IApiBauKGProtocol';
import Helpers from '../../../../helpers/Helpers';
import { ShellContentContainer } from '../../../cui-pro/ShellWithSidebar/ShellContentContainer';
import { DeleteConfirmationPopover } from '../../deleteconfirmationpopover/DeleteConfirmationPopover';
import { EditProtocolDrawer } from '../../drawers/EditProtocolDrawer/EditProtocolDrawer';
import { IProjectProtocolsProps } from './IProjectProtocols';

export const ProjectProtocols = (props: IProjectProtocolsProps ) => {

    const [protocols, setProtocols] = useState([...props.project.baukg?.protocols]);
    const [selected, setSelected] = useState<IApiBauKGProtocol | undefined>(undefined);
    const protocolsRef = React.useRef<IApiBauKGProtocol[]>();

    const isDesktop = useBreakpointValue({ base: false, lg: true });

    React.useEffect(() => {
        setProtocols([...props.project.baukg?.protocols]);
        setSelected(undefined);
    }, [props.project]);

    React.useEffect(() => {
        protocolsRef.current = protocols;
    }, [protocols]);

    React.useEffect(() => {
        return () => { (protocolsRef.current != undefined) && props.onUnmount(protocolsRef.current) };
    }, []);

    return (
        <ShellContentContainer
            leftButton={undefined}
            rightButton={
                isDesktop ? (
                    <Button variant='primary' 
                        onClick={() => props.onCreateProtocol(props.project)}
                        disabled={protocols.filter(p => !p.isSubmitted).length > 0}
                        leftIcon={<FiPlus />}
                    >
                        Protokoll erstellen
                    </Button>
                ) : (
                    <IconButton icon={<FiPlus />} 
                                variant="primary" 
                                aria-label='Protokoll hinzufügen'
                                onClick={() => props.onCreateProtocol(props.project)}
                                disabled={protocols.filter(p => !p.isSubmitted).length > 0} 
                    />
                )
            }
            buttonsInTitleRow={true}
            justifyButtons={"end"}
        >
            <Stack spacing="4" height="100%">
                <Box py="0" overflowY="auto">
                    <List listStyleType="none" spacing="4">
                    {protocols.sort((a, b) => parseInt(a.id) < parseInt(b.id) ? 1 : -1).map(protocol => (
                        <ListItem
                            key={protocol.id}
                            value={protocol.id}
                            bg="bg-surface"
                            p="4"
                            my="2"
                            mx="2"
                            boxShadow={mode('sm', 'sm-dark')}
                            position="relative"
                            borderRadius="lg"
                            cursor={(protocol.isSubmitted && protocol.url && protocol.url.length > 0) ? "pointer" : undefined}
                            onClick={() => {
                                if(protocol.isSubmitted && protocol.url && protocol.url.length > 0) {
                                    window.open(protocol.url, "_blank")
                                } else if(!protocol.isSubmitted) {
                                    setSelected(protocol);
                                }
                            }}
                        >
                            <HStack spacing="4" justifyContent="space-between">
                                <VStack spacing="2" align="left">
                                    <Text fontWeight="medium" fontSize={{ base: "sm", md: "md" }}>
                                        Protokoll Nr. {protocol.id}
                                    </Text>
                                    <HStack>
                                        <Text color="muted" fontSize={{ base: "xs", md: "sm" }}>
                                            {`Erstellt von: ${protocol.author}`}
                                        </Text>
                                        <Badge colorScheme={protocol.isSubmitted ? 'green' : 'red'} size="sm">
                                            { protocol.isSubmitted ? 'abgeschlossen' : 'ausständig' }
                                        </Badge>
                                        {protocol.url && protocol.url.length > 0 && (
                                            <Badge colorScheme="blue" size="sm">
                                                {`versendet am ${Helpers.formatDate(protocol.submittedAt.toString())}`}
                                            </Badge>
                                        )}
                                    </HStack>
                                </VStack>
                                {!protocol.isSubmitted && (
                                    <HStack> 
                                        <IconButton aria-label='preview' icon={<FiEye fontSize="1.25rem" />} variant='ghost' 
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        props.onPreviewProtocol(props.project.id, protocol.id);
                                                    }}/>
                                        <DeleteConfirmationPopover
                                            header="Protokoll löschen?"
                                            body="Dieser Vorgang kann nicht rückgängig gemacht werden!"
                                            placement='left'
                                            trigger={
                                                <IconButton aria-label='delete' icon={<FiTrash2 fontSize="1.25rem" />} variant='ghost' onClick={(e) => e.stopPropagation()} />
                                            }
                                            onConfirm={(e) => { e.stopPropagation(); props.onDeleteProtocol(protocol); } }
                                        />
                                    </HStack>
                                )}
                            </HStack>
                        </ListItem>
                    ))}
                    </List>
                </Box>
            </Stack>
            <EditProtocolDrawer 
                protocol={selected}
                onClose={(updated: IApiBauKGProtocol) => {
                    setProtocols(oldProtocols => {
                        var index = oldProtocols.findIndex(p => p.id === updated.id);
                        var newProtocols = [...oldProtocols];
                        if(index >= 0) {
                            newProtocols.splice(index, 1, updated);
                        }
                        return newProtocols;
                    })
                    setSelected(undefined);
                }}
                project={props.project}
                onPreview={props.onPreviewProtocol}
            />
        </ShellContentContainer>
    );

}