import React, { useState, useEffect, useRef } from "react";
import { useMsal } from "@azure/msal-react";
import { IApiProject } from "../../../api/models/IApiProject";
import API from "../../../api/API";
import { TableWithSearch } from "../../cui-pro/TableWithSearch/TableWithSearch";
import { Badge, Box, Button, ButtonGroup, Container, Divider, Editable, EditableInput, EditablePreview, HStack, Icon, IconButton, Input, InputGroup, InputLeftElement, List, ListItem, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Tag, TagLeftIcon, Text, Textarea, Toast, useBreakpointValue, useColorModeValue as mode, VStack, } from "@chakra-ui/react";
import { FiEdit2, FiSearch, FiTrash2, FiPlay, FiMail, FiSave } from "react-icons/fi";
import {
    useNavigate 
  } from "react-router-dom";
import { ShellContentContainer } from "../../cui-pro/ShellWithSidebar/ShellContentContainer";
import { ISettingsProps } from "./ISettings";
import { ConfigItemEditor } from "./ConfigItemEditor";
import { IApiBauKGTrade } from "../../../api/models/IApiBauKGTrade";
import toast from 'react-hot-toast';
import { IApiBauKGDeficit } from "../../../api/models/IApiBauKGDeficit";
import { SafetyChecklistEditor } from "../safetychecklisteditor/SafetyChecklistEditor";
import { IApiBauKGChecklistTemplate } from "../../../api/models/IApiBauKGChecklistTemplate";
import { AutoResizeTextarea } from "../drawers/EditProtocolDrawer/AutoSizeTextarea";

export const Settings: React.FunctionComponent<ISettingsProps> = (props: ISettingsProps) => {

    const navigate = useNavigate();

    const [trades, setTrades] = useState<IApiBauKGTrade[]>([]);
    const [tradesFilter, setTradesFilter] = useState("");

    const isDesktop = useBreakpointValue({ base: false, lg: true });

    const [tab, setTab] = useState(0);

    const [deficits, setDeficits] = useState<IApiBauKGDeficit[]>([]);

    const [checklistTemplate, setChecklistTemplate] = useState<IApiBauKGChecklistTemplate>({} as IApiBauKGChecklistTemplate);

    const deficitQueryTimeout = useRef<any>();

    const apiDebounceInterval = 500;

    useEffect(() => {
        API.getTrades().then(trades => setTrades(trades));
        API.getChecklistTemplate().then(result => setChecklistTemplate(result));
    }, []);

    const SaveChecklistButton = () => (isDesktop ? 
        (<Button leftIcon={<FiSave />} 
                 variant="primary" 
                 onClick={() => {
                    API.updateChecklistTemplate(checklistTemplate).then(result => {
                       setChecklistTemplate(result);
                       toast.success(`Erfolgreich aktulisiert!`);
                    });
                }}>
            Speichern
        </Button>)
        :
        (<IconButton aria-label='Checkliste speichern' 
                    variant="primary" 
                    icon={<FiSave />} 
                    onClick={() => {
                        API.updateChecklistTemplate(checklistTemplate).then(result => {
                           setChecklistTemplate(result);
                           toast.success(`Erfolgreich aktulisiert!`);
                        });
                    }}
        />)
    );
    
    return (
        <ShellContentContainer
            leftButton={undefined}
            rightButton={((tab === 2) && (<SaveChecklistButton />)) || (<></>)}
            overflowY={false}
            justifyButtons="end"
            buttonsInTitleRow={true /* for alignment of "save"-button */}
        >
                <Tabs index={tab} onChange={index => setTab(index)} p={2} h="inherit" style={{ display: 'flex', flexDirection: 'column' }}>
                    <TabList>
                        <Tab>{"Gewerke"}</Tab>
                        <Tab>{isDesktop ? "Mängelbeschreibungen" : "Mängel"}</Tab>
                        <Tab>{isDesktop ? "Sicherheitscheckliste (Standard)" : "Checkliste"}</Tab>
                    </TabList>
                            <TabPanels h="inherit" minH="0">
                                <TabPanel h="inherit" px={0} py={2}>
                                    <ConfigItemEditor 
                                        items={trades.filter(trade => trade.value.toLowerCase().includes(tradesFilter))}
                                        inputType={Input}
                                        onFilterChanged={(filter: string) => {
                                            setTradesFilter(filter.toLowerCase());
                                        }}
                                        onAdd={(value: string) => {
                                            API.addTrade(value).then(result => {
                                                setTrades(oldTrades => [...oldTrades, result])
                                                toast.success(`Erfolgreich erstellt!\n(${result.value})`);
                                            });
                                        }}
                                        onRenderItem={(trade: IApiBauKGTrade) => 
                                            (<Text fontWeight="medium" fontSize="sm">{trade.value}</Text>)
                                        }
                                        onDelete={(item: IApiBauKGTrade) => {
                                            API.deleteTrade(item.id).then(result => {
                                                setTrades(oldTrades => {
                                                    var index = oldTrades.findIndex(d => d.id === result.id);
                                                    var newTrades = [...oldTrades];
                                                    newTrades.splice(index, 1);
                                                    return newTrades;
                                                });
                                                toast.success(`Erfolgreich gelöscht!`);
                                            });
                                        }}
                                    />
                                </TabPanel>
                                <TabPanel h="inherit" px={0} py={2}>
                                    <ConfigItemEditor 
                                        items={deficits}
                                        inputType={AutoResizeTextarea}
                                        onFilterChanged={(filter: string) => {
                                            //Clear the previous timeout.
                                            clearTimeout(deficitQueryTimeout.current)

                                            // If there is no search term, do not make API call
                                            if (!filter || filter.length < 4) {
                                                if(deficits.length > 0) {
                                                    setDeficits([]);
                                                }
                                                return;
                                            }
                                        
                                            deficitQueryTimeout.current = setTimeout(() => {
                                                API.queryDeficits(filter).then(result => setDeficits(result));    
                                            }, apiDebounceInterval);
                                        }}
                                        onAdd={(value: string) => {
                                            API.addDeficit(value).then(result => {
                                                setDeficits(oldDeficits => [...oldDeficits, result])
                                                toast.success(`Erfolgreich erstellt!`);
                                            });
                                        }}
                                        onRenderItem={(deficit: IApiBauKGDeficit) => 
                                            (
                                                <Editable defaultValue={deficit.value} w='100%' onSubmit={e => {
                                                    if(e != deficit.value && e.length >= 4) {
                                                        API.updateDeficit(deficit.id, e).then(updatedDeficit => {
                                                            setDeficits(oldDeficits => {
                                                                var newDeficits = [ ...oldDeficits ];
                                                                var index = oldDeficits.indexOf(deficit);
                                                                newDeficits.splice(index, 1, updatedDeficit);
                                                                return newDeficits;
                                                            });
                                                            toast.success(`Erfolgreich aktualisiert!`);
                                                        });
                                                    }
                                                }}>
                                                    <EditablePreview />
                                                    <EditableInput as={AutoResizeTextarea}/>
                                                </Editable>
                                            )
                                        }
                                        onDelete={(item: IApiBauKGDeficit) => {
                                            API.deleteDeficit(item.id).then(result => {
                                                setDeficits(oldDeficits => {
                                                    var index = oldDeficits.findIndex(d => d.id === result.id);
                                                    var newDeficits = [...oldDeficits];
                                                    newDeficits.splice(index, 1);
                                                    return newDeficits;
                                                });
                                                toast.success(`Erfolgreich gelöscht!`);
                                            });
                                        }}
                                    />
                                </TabPanel>
                                <TabPanel h="inherit" px={0} py={2} overflowY="auto">
                                    <SafetyChecklistEditor checklist={checklistTemplate.value} 
                                                           setChecklist={(checklist) => {
                                                                var value = (typeof checklist === 'function') ? 
                                                                                checklist(checklistTemplate.value) : checklist;
                                                                setChecklistTemplate(oldTemplate => ({ ...oldTemplate, value }));
                                                           }}
                                                        />
                                </TabPanel>
                            </TabPanels>
                        </Tabs>


          
        </ShellContentContainer>
    );
}