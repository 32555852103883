import { ColorModeScript } from "@chakra-ui/react";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { App } from "./App";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate 
} from "react-router-dom";

const container = document.getElementById("root");
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container);

const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <React.StrictMode>
    <ColorModeScript />
      <MsalProvider instance={msalInstance}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
        
      </MsalProvider>
  </React.StrictMode>
);