import { As, Box, Button, ButtonGroup, ButtonProps, Container, useColorModeValue, BoxProps, Divider, Textarea ,Flex, Heading, HStack, Icon, IconButton, useColorModeValue as mode, IconButtonProps, Input, Stack, Text, useEditableControls, VStack, StackDivider, InputGroup, InputLeftElement, useBreakpointValue, Badge, Table, Tbody, Td, Th, Thead, Tr, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, ListIcon, List, ListItem, Tag, TagLeftIcon, FormControl, FormHelperText, FormLabel } from '@chakra-ui/react'
import * as React from 'react'
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
  } from '@chakra-ui/react'
  import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { useState, useRef } from 'react';
import { IAddPartyDrawerProps } from './IAddPartyDrawer';
import { IApiContact } from '../../../../api/models/IApiContact';
import API from '../../../../api/API';
import { FiCheck, FiCoffee, FiEdit2, FiMail, FiSquare, FiX } from 'react-icons/fi';
import { IApiBauKGTrade } from '../../../../api/models/IApiBauKGTrade';
import { RadioIconButton, RadioIconButtonGroup } from '../../../cui-pro/RadioIconButtonGroup/RadioIconButtonGroup';
import { IApiProjectBauKGParty } from '../../../../api/models/IApiProject';

const defaultParty: IApiProjectBauKGParty = {
    active: true,
    contact: undefined,
    trade:  undefined,
    startDate: `${String(new Date().getDate()).padStart(2, '0')}.${String(new Date().getMonth() + 1).padStart(2, '0')}.${new Date().getFullYear()}`,
    aba: "no",
    uwn: "no",
    bev: "no"
};

export const AddPartyDrawer = (props: IAddPartyDrawerProps) => {

    const [party, setParty] = useState<IApiProjectBauKGParty>(defaultParty);

    const [contacts, setContacts] = useState<IApiContact[]>([]);
    const contactQueryTimeout = useRef<any>();
    const contactInputRef = useRef<any>();

    const [trades, setTrades] = useState<IApiBauKGTrade[]>([]);
    const [tradeFilter, setTradeFilter] = useState("");
    const tradeInputRef = useRef<any>();

    const [startDateValid, setStartDateValid] = useState(true);

    const apiDebounceInterval = 500;

    React.useEffect(() => {
        if(!props.isOpen) {
            setParty(defaultParty);
            setContacts([]);
            setTrades([]);
            setStartDateValid(true);
        }
    }, [props.isOpen]);

    React.useEffect(() => {
        if(props.isOpen) {
            API.getTrades().then(trades => setTrades(trades));
        }
    }, [props.isOpen]);

    return (
        <Drawer
            isOpen={props.isOpen}
            placement='right'
            onClose={props.onDiscard}
            size='lg'
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader>Beteiligte hinzufügen</DrawerHeader>
                <Divider />
                <DrawerBody>
                    <Box as="section" py={2}>
                        <Container px={0} mx={0}>
                            <Box
                                bg="bg-surface"
                                boxShadow={useColorModeValue('sm', 'sm-dark')}
                                borderRadius="lg"
                                p={4}
                            >
                                <Stack spacing="5">
                                    <Stack spacing="1">
                                        <Text fontSize="lg" fontWeight="medium">
                                            Firma
                                        </Text>
                                        <HStack>
                                            <Text fontSize="sm" color="muted">
                                                {party.contact && `${party.contact.name.length > 0 ? party.contact.name : `${party.contact.firstName} ${party.contact.lastName}`} (${party.contact.mail})` || `(noch nicht ausgewählt)`}
                                            </Text>
                                            {party.contact && (
                                                <IconButton
                                                    icon={<FiEdit2 />}
                                                    variant="ghost"
                                                    aria-label="Bearbeiten"
                                                    onClick={() => setParty(old => ({ ...old, contact: undefined}))}
                                                    maxH='24px'
                                                />
                                            )}
                                        </HStack>
                                    </Stack>
                                    <VStack alignItems='start' visibility={party.contact ? 'collapse' : 'visible'} height={party.contact ? 0 : undefined}>
                                        <Input placeholder='Firma' size='md' ref={contactInputRef} onChange={() => {
                                            //Clear the previous timeout.
                                            clearTimeout(contactQueryTimeout.current)

                                            // If there is no search term, do not make API call
                                            if (!contactInputRef.current.value.trim() || contactInputRef.current.value.length < 4) {
                                                setParty(old => ({ ...old, contact: undefined}));
                                                setContacts([]);
                                                return;
                                            }
                                        
                                            contactQueryTimeout.current = setTimeout(() => {
                                                API.queryContacts(contactInputRef.current.value).then(result => setContacts(result));    
                                            }, apiDebounceInterval);
                                        }}/>
                                        <List overflowY='auto' maxH='260px' w='100%' mt={2}>
                                            {contacts.map(contact => (
                                                <ListItem
                                                    key={`${contact.id}-${contact.sourceId}-${contact.mail}`}
                                                    value={contact.mail}
                                                    bg="bg-surface"
                                                    px="4"
                                                    py="4"
                                                    m="1"
                                                    borderColor={contact === party.contact ? 'var(--chakra-colors-accent)' : 'unset'}
                                                    borderWidth={contact === party.contact ? '2px' : '0px'}
                                                    boxShadow={mode('sm', 'sm-dark')}
                                                    position="relative"
                                                    borderRadius="lg"
                                                    cursor='pointer'
                                                    onClick={() => {
                                                        setParty(old => ({ ...old, contact })); 
                                                        setContacts([]);
                                                        contactInputRef.current.value = "";
                                                    }}
                                                >
                                                    <VStack spacing="2" align="start">
                                                        <VStack spacing="0" align="start">
                                                            <Text fontWeight="medium" fontSize="sm">
                                                                {contact.name}
                                                            </Text>
                                                            <Text color="muted" fontSize="xs">
                                                                {`${contact.firstName} ${contact.lastName}`}
                                                            </Text>
                                                        </VStack>
                                                        <Tag fontSize="xs" px="2">
                                                            <TagLeftIcon boxSize='12px' as={FiMail} />
                                                            {contact.mail}
                                                        </Tag>
                                                    </VStack>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </VStack>
                                </Stack>
                            </Box>
                        </Container>
                    </Box>
                    <Box as="section" py={2}>
                        <Container px={0} mx={0}>
                            <Box
                                bg="bg-surface"
                                boxShadow={useColorModeValue('sm', 'sm-dark')}
                                borderRadius="lg"
                                p={4}
                            >
                                <Stack spacing="5">
                                    <Stack spacing="1">
                                        <Text fontSize="lg" fontWeight="medium">
                                            Gewerk
                                        </Text>
                                        <HStack>
                                            <Text fontSize="sm" color="muted">
                                                {party.trade && `${party.trade.value}` || `(noch nicht ausgewählt)`}
                                            </Text>
                                            {party.trade && (
                                                <IconButton
                                                    icon={<FiEdit2 />}
                                                    variant="ghost"
                                                    aria-label="Bearbeiten"
                                                    onClick={() => setParty(old => ({ ...old, trade: undefined }))}
                                                    maxH='24px'
                                                />
                                            )}
                                        </HStack>
                                    </Stack>
                                    <VStack alignItems='start' visibility={party.trade ? 'collapse' : 'visible'} height={party.trade ? 0 : undefined}>
                                        <Input placeholder='Gewerk' size='md' ref={tradeInputRef} onChange={(e) => setTradeFilter(e.currentTarget.value)}/>
                                        <List overflowY='auto' maxH='180px' w='100%' mt={2}>
                                            {trades.filter(t => t.value.toLowerCase().indexOf(tradeFilter.toLowerCase()) >= 0).map(trade => (
                                                <ListItem
                                                    key={trade.id}
                                                    value={trade.value}
                                                    bg="bg-surface"
                                                    px="4"
                                                    py="4"
                                                    m="1"
                                                    borderColor={trade === party.trade ? 'var(--chakra-colors-accent)' : 'unset'}
                                                    borderWidth={trade === party.trade ? '2px' : '0px'}
                                                    boxShadow={mode('sm', 'sm-dark')}
                                                    position="relative"
                                                    borderRadius="lg"
                                                    cursor='pointer'
                                                    onClick={() => {
                                                        setParty(old => ({ ...old, trade })); 
                                                        setTradeFilter("");
                                                        tradeInputRef.current.value = "";
                                                    }}
                                                >
                                                    <VStack spacing="2" align="start">
                                                        <Text fontWeight="medium" fontSize="sm">
                                                            {trade.value}
                                                        </Text>
                                                    </VStack>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </VStack>
                                </Stack>
                            </Box>
                        </Container>
                    </Box>
                    <Box as="section" py={2}>
                        <Container px={0} mx={0}>
                            <Box
                                bg="bg-surface"
                                boxShadow={useColorModeValue('sm', 'sm-dark')}
                                borderRadius="lg"
                                p={4}
                            >
                                <Stack spacing="5">
                                    <FormControl>
                                        <FormLabel fontSize="md" >Arbeitsbeginn (TT.MM.JJJJ)</FormLabel>
                                        <Input type='date' defaultValue={party.startDate} size='md' maxLength={10} isInvalid={!startDateValid} onChange={(e) => {
                                            /*
                                            function isValidDate(s: string) {
                                                // https://stackoverflow.com/a/38617035
                                                var separators = ['\\.', '\\-', '\\/'];
                                                var bits = s.split(new RegExp(separators.join('|'), 'g'));
                                                var d = new Date(parseInt(bits[2]), parseInt(bits[1]) - 1, parseInt(bits[0]));
                                                return d.getFullYear() == parseInt(bits[2]) && d.getMonth() + 1 == parseInt(bits[1]) 
                                                    && 
                                                    parseInt(bits[2]) >= (new Date()).getFullYear();
                                            }
                                            */
                                            setParty(old => ({ ...old, startDate: e.target.value }));
                                            setStartDateValid(e.target.value !== null && e.target.value !== undefined && e.target.value !== "");
                                        }}/>
                                    </FormControl>
                                </Stack>
                            </Box>
                        </Container>
                    </Box>
                    <Box as="section" py={2}>
                        <Container px={0} mx={0}>
                            <Box
                                bg="bg-surface"
                                boxShadow={useColorModeValue('sm', 'sm-dark')}
                                borderRadius="lg"
                                p={4}
                            >
                                <Stack spacing="5">
                                    <FormControl>
                                        <FormLabel fontSize="md" >Arbeitsbeginnanzeige (ABA)</FormLabel>
                                        <Stack spacing="5" mt={2}>
                                            <RadioIconButtonGroup key="aba" defaultValue={party.aba} size="sm" onChange={(value) => setParty(old => ({ ...old, aba: value }))}>
                                                <RadioIconButton value="yes" aria-label="yes" icon={<FiCheck fontSize="1.25rem" />} />
                                                <RadioIconButton value="no" aria-label="no" icon={<FiX fontSize="1.25rem" />} />
                                                <RadioIconButton value="ignore" aria-label="ignore" icon={<FiSquare fontSize="1.25rem" />} />
                                            </RadioIconButtonGroup>
                                        </Stack>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel fontSize="md" >Unterweisungsnachweis (UWN)</FormLabel>
                                        <Stack spacing="5" mt={2}>
                                            <RadioIconButtonGroup key="uwn" defaultValue={party.uwn} size="sm" onChange={(value) => setParty(old => ({ ...old, uwn: value }))}>
                                                <RadioIconButton value="yes" aria-label="yes" icon={<FiCheck fontSize="1.25rem" />} />
                                                <RadioIconButton value="no" aria-label="no" icon={<FiX fontSize="1.25rem" />} />
                                                <RadioIconButton value="ignore" aria-label="ignore" icon={<FiSquare fontSize="1.25rem" />} />
                                            </RadioIconButtonGroup>
                                        </Stack>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel fontSize="md" >Baustellenevaluierung (BEV)</FormLabel>
                                        <Stack spacing="5" mt={2}>
                                            <RadioIconButtonGroup key="bev" defaultValue={party.bev} size="sm" onChange={(value) => setParty(old => ({ ...old, bev: value }))}>
                                                <RadioIconButton value="yes" aria-label="yes" icon={<FiCheck fontSize="1.25rem" />} />
                                                <RadioIconButton value="no" aria-label="no" icon={<FiX fontSize="1.25rem" />} />
                                                <RadioIconButton value="ignore" aria-label="ignore" icon={<FiSquare fontSize="1.25rem" />} />
                                            </RadioIconButtonGroup>
                                        </Stack>
                                    </FormControl>
                                </Stack>
                            </Box>
                        </Container>
                    </Box>
                </DrawerBody>
                <DrawerFooter>
                    <HStack>
                        <Button variant='secondary' mr={3} onClick={props.onDiscard}>
                            Abbrechen
                        </Button>
                        <Button variant='primary' onClick={() => props.onSubmit(party)}
                                isDisabled={!party.contact || !party.trade || !startDateValid}>
                            Speichern
                        </Button>
                    </HStack>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
}