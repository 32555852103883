import { Icon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Progress,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import * as React from 'react'
import {
  FiBarChart2,
  FiBookmark,
  FiCheckSquare,
  FiHelpCircle,
  FiHome,
  FiSearch,
  FiSettings,
  FiUsers,
  FiFileText,
  FiLogOut,
  FiFile,
  FiBookOpen,
  FiPhone,
  FiPhoneCall,
  FiInfo,
  FiBook
} from 'react-icons/fi'
import { IShellWithSidebarNavbarProps } from './IShellWithSidebar'
import { Logo } from './Logo'
import { NavbarItems } from './NavbarItems'
import { NavButton } from './NavButton'
import { UserProfile } from './UserProfile'

export const Sidebar: React.FunctionComponent<IShellWithSidebarNavbarProps> = (props: IShellWithSidebarNavbarProps) => (
  <Flex as="section" minH="100vh" bg="bg-canvas">
    <Flex
      flex="1"
      bg="bg-surface"
      overflowY="auto"
      boxShadow={useColorModeValue('md', 'sm-dark')}
      maxW={{ base: 'full', sm: 'xs' }}
      minW={{ base: 'unset', md: '320px' }}
      py={{ base: '6', sm: '8' }}
      px={{ base: '4', sm: '6' }}
    >
      <Stack justify="space-between" spacing="1" w="100%" >
        <Stack spacing={{ base: '5', sm: '6' }} shouldWrapChildren>
          <Logo />
          <Divider />
          <UserProfile
            name={props.user.name}
            image={props.user.image}
            email={props.user.email}
          />
          <Divider />
          <Text fontSize="sm" fontWeight="medium">
              Organisation
            </Text>
          <Stack spacing="1.5">
            <NavButton label="Projekte" 
                       icon={FiHome} 
                       isActive={props.selectedPage === NavbarItems.Projects} 
                       onClick={() => props.onPageSelected(NavbarItems.Projects)}
                      />
            <NavButton label="Einstellungen" 
                       icon={FiSettings} 
                       isActive={props.selectedPage === NavbarItems.Settings}
                       onClick={() => props.onPageSelected(NavbarItems.Settings)}
                      />
          </Stack>
          {
            (props.projectName && props.projectName.length > 0 && (
              <Stack>
                <Text fontSize="sm" fontWeight="medium">
                  {props.projectName}
                </Text>
                <Stack spacing="1.5">
                  <NavButton label="Übersicht" 
                             icon={FiBarChart2} 
                             isActive={props.selectedPage === NavbarItems.ProjectOverview} 
                             onClick={() => props.onPageSelected(NavbarItems.ProjectOverview)}
                  />
                  <NavButton label="Protokolle" 
                             icon={FiFileText} 
                             isActive={props.selectedPage === NavbarItems.ProjectProtocols}
                             onClick={() => props.onPageSelected(NavbarItems.ProjectProtocols)}
                  />
                  <NavButton label="Kontakte" 
                             icon={FiBook} 
                             isActive={props.selectedPage === NavbarItems.ProjectContacts}
                             onClick={() => props.onPageSelected(NavbarItems.ProjectContacts)}
                  />
                  <Divider />
                  <NavButton label="Stammdaten" 
                            icon={FiBookmark} 
                            isActive={props.selectedPage === NavbarItems.ProjectCoreData} 
                            onClick={() => props.onPageSelected(NavbarItems.ProjectCoreData)}
                            />
                  <NavButton label="Beteiligtenliste" 
                            icon={FiUsers} 
                            isActive={props.selectedPage === NavbarItems.ProjectParties} 
                            onClick={() => props.onPageSelected(NavbarItems.ProjectParties)}
                            />
                  <NavButton label="Dokumente" 
                            icon={FiFile} 
                            isActive={props.selectedPage === NavbarItems.ProjectDocuments} 
                            onClick={() => props.onPageSelected(NavbarItems.ProjectDocuments)}
                            />
                  <NavButton label="Sicherheitscheckliste" 
                            icon={FiCheckSquare} 
                            isActive={props.selectedPage === NavbarItems.ProjectSafetyChecklist} 
                            onClick={() => props.onPageSelected(NavbarItems.ProjectSafetyChecklist)}
                            />
                </Stack>
              </Stack>
            ))
          }
        </Stack>
        <Stack spacing={{ base: '5', sm: '6' }}>
          <Stack spacing="1.5">
            <NavButton label="Abmelden" 
                       icon={FiLogOut} 
                       onClick={props.onLogout}
                      />
          </Stack>
        </Stack>
      </Stack>
    </Flex>
  </Flex>
)
