import { As, Box, Button, ButtonGroup, ButtonProps, Container, useColorModeValue as mode, BoxProps, Divider, Textarea ,Flex, Heading, HStack, Icon, IconButton, IconButtonProps, Input, Stack, Text, useEditableControls, VStack, StackDivider, InputGroup, InputLeftElement, useBreakpointValue, Badge, Table, Tbody, Td, Th, Thead, Tr, List, ListItem, Tag, TagLeftIcon, Editable, EditableInput, EditablePreview } from '@chakra-ui/react'
import * as React from 'react'

  import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
  } from '@chakra-ui/react'

import { useRef, useState } from 'react';
import { IApiProjectBauKGCoreData } from '../../../../api/models/IApiProject';
import { IProjectCoreDataProps } from './IProjectCoreData';
import { ShellContentContainer } from '../../../cui-pro/ShellWithSidebar/ShellContentContainer';
import { FiCheck, FiDelete, FiEdit2, FiMail, FiPlus, FiSave, FiTrash2, FiX } from 'react-icons/fi';
import { AutoResizeTextarea } from '../../drawers/EditProtocolDrawer/AutoSizeTextarea';
import { IApiContact } from '../../../../api/models/IApiContact';
import API from '../../../../api/API';
import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';

interface IContactFieldProps {
  setContact(contact: IApiContact | undefined): void;
  contact: IApiContact | undefined;
  onDelete?(): void;
}

const BasicInfoField = (props: { defaultValue: string, onSubmit(value: string): void }) => {

  const EditableControls = () => {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls()

    return isEditing ? (
      <ButtonGroup justifyContent='center' spacing={0} ml={2}>
        <IconButton size='md' variant='ghost' aria-label='submit' icon={<FiCheck />} {...getSubmitButtonProps()} />
        <IconButton size='md' variant='ghost' aria-label='cancel' icon={<FiX />} {...getCancelButtonProps()} />
      </ButtonGroup>
    ) : (
      <Flex justifyContent='center'>
        <IconButton size='md' variant='ghost' aria-label='edit' icon={<FiEdit2 />} {...getEditButtonProps()} />
      </Flex>
    )
  }

  return (
    <Editable
      textAlign='left'
      defaultValue={props.defaultValue}
      //fontSize='2xl'
      isPreviewFocusable={false}
      w="100%"
      submitOnBlur={false}
      onSubmit={(value) => props.onSubmit(value)}
    >
      <Flex flexDir="row" justifyContent="space-between" alignItems="center">
        <EditablePreview flexGrow={1} />
        <Input as={EditableInput} flexGrow={1} />
        <EditableControls />
      </Flex>
    </Editable>
  );

};

const ContactField = ({ setContact, contact, onDelete }: IContactFieldProps) => {

  const apiDebounceInterval = 500;
  const queryTimeout = useRef<any>();
  const inputRef = useRef<any>();
  const [results, setResults] = useState<IApiContact[]>([]);

  return (
    <Stack spacing="4" w="100%">
      {contact && (
        <HStack>
          <VStack spacing="2" align="start" w="100%">
            <VStack spacing="0" align="start">
              <Text fontWeight="medium" fontSize="sm">
                {contact.name}
              </Text>
              <Text color="muted" fontSize="xs">
                {`${contact.firstName} ${contact.lastName}`}
              </Text>
            </VStack>
            <Tag fontSize="xs" px="2">
              <TagLeftIcon boxSize='12px' as={FiMail} />
                {contact.mail}
            </Tag>
          </VStack>
          <HStack>
            <IconButton
              icon={<FiEdit2 />}
              variant="ghost"
              aria-label="Bearbeiten"
              onClick={() => {
                setResults([contact]);
                inputRef.current.value = contact.mail;
                setContact(undefined)}
              }
              maxH='24px'
            />
            {onDelete && (
              <IconButton
                icon={<FiTrash2 />}
                variant="ghost"
                aria-label="Löschen"
                onClick={() => onDelete()}
                maxH='24px'
              />
            )}
          </HStack>
        </HStack>
      )}
      <VStack alignItems='start' visibility={contact ? 'collapse' : 'visible'} height={contact ? 0 : undefined} m={contact ? "0 !important" : undefined}>
        <HStack w="full">
        <Input placeholder="Kontakt suchen..." size='md' flexGrow="1" ref={inputRef} onChange={() => {
          //Clear the previous timeout.
          clearTimeout(queryTimeout.current)

          // If there is no search term, do not make API call
          if (!inputRef.current.value.trim() || inputRef.current.value.length < 4) {
            setContact(undefined);
            setResults([]);
            return;
          }
                                        
          queryTimeout.current = setTimeout(() => {
            API.queryContacts(inputRef.current.value).then(value => setResults(value));    
          }, apiDebounceInterval);
        }}/>
        {onDelete && (
          <IconButton
            icon={<FiTrash2 />}
            variant="ghost"
            aria-label="Löschen"
            onClick={() => onDelete()}
            maxH='24px'
          />
        )}
        </HStack>
        <List overflowY='auto' maxH='320px' w='100%' mt={2}>
          {results.map(c => (
            <ListItem
              key={`${c.id}-${c.sourceId}-${c.mail}`}
              value={c.mail}
              bg="bg-surface"
              px="4"
              py="2"
              m="1"
              borderColor={c === contact ? 'var(--chakra-colors-accent)' : 'unset'}
              borderWidth={c === contact ? '2px' : '0px'}
              boxShadow={mode('sm', 'sm-dark')}
              position="relative"
              borderRadius="lg"
              cursor='pointer'
              onClick={() => {
                setContact(c); 
                setResults([]);
                inputRef.current.value = "";
              }}
            >
              <VStack spacing="2" align="start">
                <VStack spacing="0" align="start">
                  <Text fontWeight="medium" fontSize="sm">
                    {c.name}
                  </Text>
                  <Text color="muted" fontSize="xs">
                    {`${c.firstName} ${c.lastName}`}
                  </Text>
                </VStack>
                <Tag fontSize="xs" px="2">
                  <TagLeftIcon boxSize='12px' as={FiMail} />
                  {c.mail}
                </Tag>
              </VStack>
            </ListItem>
          ))}
        </List>
      </VStack>
    </Stack>
  );
};

export const ProjectCoreData = (props: IProjectCoreDataProps) => {

    const [data, setData] = useState<IApiProjectBauKGCoreData>({ 
      ...props.project.baukg.coredata,
      beobachter: props.project.baukg.coredata.beobachter ?? [] // handles "legacy" configs
    });
    const isDesktop = useBreakpointValue({ base: false, lg: true });

    const SaveCoreDataButton = () => (isDesktop ?
      (<Button leftIcon={<FiSave />} variant="primary" onClick={() => props.onSave(data)}>Speichern</Button>)
      :
      (<IconButton variant="primary" icon={<FiSave />} aria-label='Speichern' onClick={() => props.onSave(data)} />)
    );

    return (
      <ShellContentContainer
            leftButton={undefined}
            rightButton={<SaveCoreDataButton />}
            buttonsInTitleRow={true}
            justifyButtons={"end"}
        >
          <Container py={{ base: '4', md: '8' }}>
            <Stack spacing="5" divider={<StackDivider />}>
              <FormControl id="bauvorhaben">
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  spacing={{ base: '1.5', md: '8' }}
                  justify="space-between"
                >
                  <FormLabel fontSize='md' variant="inline">Bauvorhaben</FormLabel>
                  <BasicInfoField defaultValue={data.bauvorhaben} onSubmit={(value) => setData(oldData => ({ ...oldData, bauvorhaben: value }))} />
                  {/* <Input maxW={{ md: '3xl' }} defaultValue={data.bauvorhaben} onBlur={e => setData(oldData => ({ ...oldData, bauvorhaben: e.target.value }))} /> */}
                </Stack>
              </FormControl>
              <FormControl id="adresse">
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  spacing={{ base: '1.5', md: '8' }}
                  justify="space-between"
                >
                  <FormLabel fontSize='md' variant="inline">Adresse</FormLabel>
                  <BasicInfoField defaultValue={data.adresse} onSubmit={(value) => setData(oldData => ({ ...oldData, adresse: value }))} />
                  {/* <Input maxW={{ md: '3xl' }} defaultValue={data.adresse} onBlur={e => setData(oldData => ({ ...oldData, adresse: e.target.value }))}/> */}
                </Stack>
              </FormControl>
              <FormControl id="bauherr">
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  spacing={{ base: '1.5', md: '8' }}
                  justify="space-between"
                >
                  <Box>
                    <FormLabel fontSize='md' variant="inline">Bauherr</FormLabel>
                  </Box>
                  <ContactField contact={data.bauherr} setContact={(contact) => setData(old => ({ ...old, bauherr: contact }))} />
                </Stack>
              </FormControl>
              <FormControl id="oeba">
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  spacing={{ base: '1.5', md: '8' }}
                  justify="space-between"
                >
                  <Box>
                    <FormLabel fontSize='md' variant="inline">ÖBA</FormLabel>
                  </Box>
                  <ContactField contact={data.oeba} setContact={(contact) => setData(old => ({ ...old, oeba: contact }))} />
                </Stack>
              </FormControl>
              <FormControl id="planungskoordinator">
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  spacing={{ base: '1.5', md: '8' }}
                  justify="space-between"
                >
                  <Box>
                    <FormLabel fontSize='md' variant="inline">Planungskoordinator</FormLabel>
                  </Box>
                  <ContactField contact={data.planungskoordinator} setContact={(contact) => setData(old => ({ ...old, planungskoordinator: contact }))} />
                </Stack>
              </FormControl>
              <FormControl id="projektleiter">
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  spacing={{ base: '1.5', md: '8' }}
                  justify="space-between"
                >
                  <Box>
                    <FormLabel fontSize='md' variant="inline">Projektleiter gem. BauKG</FormLabel>
                  </Box>
                  <ContactField contact={data.projektleiter} setContact={(contact) => setData(old => ({ ...old, projektleiter: contact }))} />
                </Stack>
              </FormControl>
              <FormControl id="baustellenkoordinator">
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  spacing={{ base: '1.5', md: '8' }}
                  justify="space-between"
                >
                  <Box>
                    <FormLabel fontSize='md' variant="inline">Baustellenkoordinator</FormLabel>
                  </Box>
                  <ContactField contact={data.baustellenkoordinator} setContact={(contact) => setData(old => ({ ...old, baustellenkoordinator: contact }))} />
                </Stack>
              </FormControl>
              
              <FormControl id="observers">
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  spacing={{ base: '1.5', md: '8' }}
                  justify="space-between"
                >
                  <Box>
                    <FormLabel fontSize='md' variant="inline">Zusätzliche Protokollempfänger</FormLabel>
                    <Button leftIcon={<FiPlus />} 
                            color='green' 
                            size='xs' 
                            variant='outline'
                            mt={2}
                            onClick={() => setData(old => ({
                              ...old, beobachter: [...old.beobachter!, undefined]
                            }))}
                    >
                      Hinzufügen
                    </Button>
                  </Box>
                  <VStack w="full" spacing="4">
                    {data.beobachter && data.beobachter.map((b, i) => (
                      <ContactField contact={b} 
                                    key={`${i}-${b ? b.mail : "undefined"}`}
                                    onDelete={() => setData(oldData => {
                                      var newData = {...oldData};
                                      newData.beobachter = [...oldData.beobachter!];
                                      newData.beobachter.splice(i, 1);
                                      return newData;
                                    })} 
                                    setContact={(contact) => setData(oldData => {
                                      var newData = {...oldData};
                                      newData.beobachter = [...oldData.beobachter!];
                                      newData.beobachter.splice(i, 1, contact);
                                      return newData;
                                    })} 
                      />
                    ))}
                  </VStack>
                </Stack>
              </FormControl>
            </Stack>
        </Container>
      </ShellContentContainer>
      )
  }