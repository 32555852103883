import { IApiContact } from "../api/models/IApiContact";

export default class Helpers {

    public static formatDate(value: string) : string {
        try {
            if(value.indexOf('T') > 0) {
                var parts = value.substring(0, 10).split("-");
                return `${parts[2]}.${parts[1]}.${parts[0]}`;
            }
        } catch {
            return value;
        }

        try {
            if(value.indexOf('-') < 0) {
                return value;
            }
        } catch {
            return value;
        }
        
        try {
            var parts = value.split("-");
            return `${parts[2]}.${parts[1]}.${parts[0]}`;
        } catch {
            return value;
        }
    }

    public static compareDates(d1: string, d2: string): number {

        var d1Parts: string[];
        var d2Parts: string[];

        try {
            d1Parts = d1.split("-");
            if(d1Parts.length !== 3) {
                throw "d1 invalid";
            }
        } catch {
            d1Parts = [];
        }

        try {
            d2Parts = d2.split("-");
            if(d2Parts.length !== 3) {
                throw "d2 invalid";
            }
        } catch {
            d2Parts = [];
        }

        if(d1Parts.length === 0 && d2Parts.length === 0) {
            return 0;
        } else if(d1Parts.length === 0) {
            return 1;
        } else if (d2Parts.length === 0) {
            return -1;
        }

        for(var i = 0; i < 3; i++) {
            var d1Int = parseInt(d1Parts[i]);
            var d2Int = parseInt(d2Parts[i]);
            if(d1Int < d2Int) {
                return 1;
            } else if (d2Int < d1Int) {
                return -1;
            } else {
                continue;
            }
        }

        return 0;
    }

    public static isEmployee(contact: IApiContact) : boolean {
        return contact.mail.endsWith("@ferro-partner.at");
    }
}