import { As, Box, Button, ButtonGroup, ButtonProps, Container, useColorModeValue, BoxProps, Divider, Textarea ,Flex, Heading, useColorModeValue as mode, HStack, Icon, IconButton, IconButtonProps, Input, Stack, Text, useEditableControls, VStack, StackDivider, InputGroup, InputLeftElement, useBreakpointValue, Badge, Table, Tbody, Td, Th, Thead, Tr, ListItem, List, Square, UnorderedList, EditableInput, Editable, EditablePreview, ListIcon, useEditableState, Tag, TagLeftIcon, Checkbox } from '@chakra-ui/react'
import * as React from 'react'

  import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
  } from '@chakra-ui/react'

import { useState, useRef } from 'react';
import { IApiProjectBauKGCoreData } from '../../../../api/models/IApiProject';
import { IProjectContactsProps } from './IProjectContacts';
import { FiArrowDown, FiArrowUp, FiCheck, FiDelete, FiEdit2, FiFile, FiFileText, FiHome, FiLink, FiLink2, FiMail, FiPhone, FiPlus, FiSearch, FiSmartphone, FiTrash, FiTrash2 } from 'react-icons/fi';
import { ShellContentContainer } from '../../../cui-pro/ShellWithSidebar/ShellContentContainer';
import { IApiBauKGDocument } from '../../../../api/models/IApiBauKGDocument';
import API from '../../../../api/API';
import { IApiContact } from '../../../../api/models/IApiContact';
import Helpers from '../../../../helpers/Helpers';

interface IProjectContact extends IApiContact {
    role?: string | undefined;
    trade?: string | undefined;
    active?: boolean | undefined;
}

export const ProjectContacts = (props: IProjectContactsProps) => {

    const { project } = props;

    const isDesktop = useBreakpointValue({ base: false, lg: true });
    const showTable = useBreakpointValue({ base: false, xl: true });

    var [hideEmployees, setHideEmployees] = useState<boolean>(true);
    const HideEmployeesCheckbox = () => (
        <Checkbox defaultChecked={hideEmployees} onChange={e => setHideEmployees(e.target.checked)}>Mitarbeiter ausblenden</Checkbox>
    );
    var [hideInactive, setHideInactive] = useState<boolean>(true);
    const HideInactiveCheckbox = () => (
        <Checkbox defaultChecked={hideInactive} onChange={e => setHideInactive(e.target.checked)}>Inaktive ausblenden</Checkbox>
    );
    var [filterText, setFilterText] = useState<string>("");

    var [contacts, setContacts] = useState<IProjectContact[]>([]);
    React.useEffect(() => {
        var newContacts: IProjectContact[] = [];
        if(project.baukg.coredata.bauherr && project.baukg.coredata.bauherr.mail.length > 0) {
            newContacts.push({ ...project.baukg.coredata.bauherr, role: "Bauherr" });
        }
        if(project.baukg.coredata.oeba && project.baukg.coredata.oeba.mail.length > 0) {
            newContacts.push({ ...project.baukg.coredata.oeba, role: "ÖBA" });
        }
        if(project.baukg.coredata.planungskoordinator && project.baukg.coredata.planungskoordinator.mail.length > 0) {
            newContacts.push({ ...project.baukg.coredata.planungskoordinator, role: "Planungskoordinator" });
        }
        if(project.baukg.coredata.projektleiter && project.baukg.coredata.projektleiter.mail.length > 0) {
            newContacts.push({ ...project.baukg.coredata.projektleiter, role: "Projektleiter gem. BauKG" });
        }
        if(project.baukg.coredata.baustellenkoordinator && project.baukg.coredata.baustellenkoordinator.mail.length > 0) {
            newContacts.push({ ...project.baukg.coredata.baustellenkoordinator, role: "Baustellenkoordinator" });
        }
        project.baukg.parties.forEach(p => p.contact && newContacts.push({ ...p.contact, trade: p.trade?.value, active: p.active }));

        if(hideEmployees) {
            newContacts = newContacts.filter(c => !hideEmployees || !Helpers.isEmployee(c));
        }
        if(hideInactive) {
            newContacts = newContacts.filter(c => c.active === undefined || c.active);
        }
        if(filterText !== "") {
            var filter = filterText.toLowerCase();
            newContacts = newContacts.filter(c => (
                c.firstName.toLowerCase().indexOf(filter) >= 0
                || 
                c.lastName.toLowerCase().indexOf(filter) >= 0
                || 
                c.name.toLowerCase().indexOf(filter) >= 0
                || 
                c.mail.toLowerCase().indexOf(filter) >= 0
            ));
        }

        setContacts(newContacts);
    }, [project, hideEmployees, hideInactive, filterText]);

    const tdProps = {
        paddingLeft: "16px",
        paddingRight: "16px"
    };

    return (
        <ShellContentContainer
            leftButton={!isDesktop ? <HideEmployeesCheckbox /> : <></>}
            rightButton={!isDesktop ? <HideInactiveCheckbox /> : <></>}
            justifyButtons="flex-start"
            buttonsInTitleRow={isDesktop}
        >
            <Stack h="full">
                <Box px={{ base: '2', md: '2' }} pt="2">
                    <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between">
                        <InputGroup maxW={{base: "100%", lg: "md"}}>
                            <InputLeftElement pointerEvents="none">
                            <Icon as={FiSearch} color="muted" boxSize="5" />
                            </InputLeftElement>
                            <Input placeholder="Suchen..." onChange={e => setFilterText(e.currentTarget?.value?.toLocaleLowerCase())} />
                        </InputGroup>
                        {isDesktop && (
                            <HStack>
                                <HideEmployeesCheckbox />
                                <HideInactiveCheckbox />
                            </HStack>
                        )}
                    </Stack>
                </Box>
                <Box
                    bg="bg-surface"
                    boxShadow={{ base: 'none', md: useColorModeValue('sm', 'sm-dark') }}
                    borderRadius={useBreakpointValue({ base: 'none', md: 'lg' })}
                    py="0" overflowY="auto"
                >
                    {(showTable && (
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th {...tdProps}>Firma</Th>
                                    <Th {...tdProps}>Rolle/Gewerk</Th>
                                    <Th {...tdProps}>Adresse</Th>
                                    <Th {...tdProps}>Kontakt</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                            {contacts.map(contact => (
                                <Tr key={contact.name + contact.mail + contact.role + contact.trade}>
                                    <Td {...tdProps}>
                                        <VStack spacing="0" align="start">
                                            {contact.name.length > 0 && (
                                                <Text fontWeight="medium" fontSize="sm">
                                                    {contact.name}
                                                </Text>
                                            )}
                                            {contact.firstName.length > 0 && contact.lastName.length > 0 && (
                                                <Text color="muted" fontSize="sm">
                                                    {`${contact.firstName} ${contact.lastName}`}
                                                </Text>
                                            )}
                                        </VStack>
                                    </Td>
                                    <Td {...tdProps}>
                                        {contact.role && (
                                            <Badge colorScheme={'red'} size="sm">{contact.role}</Badge>
                                        )}
                                        {contact.trade && (
                                            <Badge colorScheme={'blue'} size="sm">{contact.trade}</Badge>
                                        )}
                                    </Td>
                                    <Td {...tdProps}>
                                        <Text fontSize="sm" lineHeight={1.5}>
                                            {contact.street1 && (<>{`${contact.street1}`}<br/></>)}
                                            {contact.street2 && (<>{`${contact.street2}`}<br/></>)}
                                            {contact.zip && contact.city && (<>{`${contact.zip} ${contact.city}`}<br/></>)}
                                            {contact.country && (<>{`${contact.country}`}</>)} 
                                        </Text>                                      
                                    </Td>
                                    <Td {...tdProps}>
                                        <VStack spacing="4" align="start">
                                            {contact.phone1 && (
                                                <a href={`tel:${contact.phone1}`}>
                                                    <Tag fontSize="xs" px="2">
                                                        <TagLeftIcon boxSize='12px' as={FiPhone} />
                                                        {contact.phone1}
                                                    </Tag>
                                                </a>
                                            )}
                                            {contact.phoneM && (
                                                <a href={`tel:${contact.phoneM}`}>
                                                    <Tag fontSize="xs" px="2">
                                                        <TagLeftIcon boxSize='12px' as={FiSmartphone} />
                                                        {contact.phoneM}
                                                    </Tag>
                                                </a>
                                            )}
                                            {contact.mail && (
                                                <a href={`mailto:${contact.mail}`}>
                                                    <Tag fontSize="xs" px="2">
                                                        <TagLeftIcon boxSize='12px' as={FiMail} />
                                                        {contact.mail}
                                                    </Tag>
                                                </a>
                                            )}
                                        </VStack>
                                    </Td>
                                </Tr>
                            ))}
                            </Tbody>
                        </Table>
                    )) || (
                        <List listStyleType="none" spacing="4">
                            {contacts.map(contact => (
                                <ListItem
                                    key={contact.name + contact.mail + contact.role + contact.trade}
                                    value={contact.mail}
                                    bg="bg-surface"
                                    p="4"
                                    my="2"
                                    mx="2"
                                    boxShadow={mode('sm', 'sm-dark')}
                                    position="relative"
                                    borderRadius="lg"
                                >
                                    <VStack spacing={{ base: "2", md: "4" }} align="start">
                                        <VStack spacing="2" align="start">
                                            <HStack>
                                                {contact.name.length > 0 && (
                                                    <Text fontWeight="medium" fontSize={{ base: "sm", md: "md" }}>
                                                        {contact.name}
                                                    </Text>
                                                )}
                                                {contact.role && (
                                                    <Badge colorScheme={'red'} size={{ base: "sm", md: "md" }}>{contact.role}</Badge>
                                                )}
                                                {contact.trade && (
                                                    <Badge colorScheme={'blue'} size={{ base: "sm", md: "md" }}>{contact.trade}</Badge>
                                                )}
                                            </HStack>
                                            {contact.firstName.length > 0 && contact.lastName.length > 0 && (
                                                <Text color="muted" fontSize={{ base: "sm", md: "md" }}>
                                                    {`${contact.firstName} ${contact.lastName}`}
                                                </Text>
                                            )}
                                        </VStack>
                                        <Divider /> 
                                        <VStack spacing="2" align="start" w="full">
                                            {contact.mail && (
                                                <a href={`mailto:${contact.mail}`} style={{ width: "100%"}}>
                                                    <Tag variant="outline" colorScheme="black" w="full" 
                                                         fontSize={{ base: "sm", md: "md" }} 
                                                         px="2" py={{ base: "2", md: "2" }}>
                                                        <TagLeftIcon as={FiMail} />
                                                        {contact.mail}
                                                    </Tag>
                                                </a>
                                            )}
                                            <HStack w="full">
                                                {contact.phone1 && (
                                                    <a href={`tel:${contact.phone1}`} style={{ flexGrow: "1"}}>
                                                        <Tag variant="outline" colorScheme="black" w="full" 
                                                             fontSize={{ base: "sm", md: "md" }} 
                                                             px="2" py={{ base: "2", md: "2" }}>
                                                            <TagLeftIcon as={FiPhone} />
                                                            {contact.phone1}
                                                        </Tag>
                                                    </a>
                                                )}
                                                {contact.phoneM && (
                                                    <a href={`tel:${contact.phoneM}`} style={{ flexGrow: "1"}}>
                                                        <Tag variant="outline" colorScheme="black" w="full" 
                                                             fontSize={{ base: "sm", md: "md" }} 
                                                             px="2" py={{ base: "2", md: "2" }}>
                                                            <TagLeftIcon as={FiSmartphone} />
                                                            {contact.phoneM}
                                                        </Tag>
                                                    </a>
                                                )}  
                                            </HStack>  
                                                     
                                        </VStack>
                                        {contact.zip && contact.city && (
                                            <>
                                                <Divider /> 
                                                <Tag variant="outline" colorScheme="black" w="full" 
                                                     fontSize={{ base: "sm", md: "md" }} 
                                                     px="2" py={{ base: "2", md: "2" }} lineHeight={1.5}>
                                                    <TagLeftIcon as={FiHome} />
                                                    {contact.street1 && (<>{`${contact.street1}`}<br/></>)}
                                                    {contact.street2 && (<>{`${contact.street2}`}<br/></>)}
                                                    {contact.zip && contact.city && (<>{`${contact.zip} ${contact.city}`}<br/></>)}
                                                    {contact.country && (<>{`${contact.country}`}</>)} 
                                                </Tag>
                                            </>
                                        )} 
                                    </VStack>
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Box>
            </Stack>
            <Container p={{ base: 0, md: 0 }} mx={0} w='100%' maxW='unset'>
                
            </Container>
        </ShellContentContainer>
    );
}