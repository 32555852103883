import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import * as React from 'react'
import { IShellWithSidebarNavbarProps } from './IShellWithSidebar'
import { Logo } from './Logo'
import { NavbarItems } from './NavbarItems'
import { Sidebar } from './Sidebar'
import { ToggleButton } from './ToggleButton'

export const Navbar: React.FunctionComponent<IShellWithSidebarNavbarProps> = (props: IShellWithSidebarNavbarProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  return (
    <Box
      width="full"
      py="4"
      px={{ base: '4', md: '8' }}
      bg="bg-surface"
      boxShadow={useColorModeValue('sm', 'sm-dark')}
      id="navbar"
    >
      <Flex justify="space-between" alignItems="center">
        <Logo />
        <ToggleButton isOpen={isOpen} aria-label="Menü öffnen" onClick={onToggle} />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          isFullHeight
          preserveScrollBarGap
          // Only disabled for showcase -> set to true!
          trapFocus={true}
        >
          <DrawerOverlay />
          <DrawerContent>
            <Sidebar  { ...props } 
                      onPageSelected={(page: NavbarItems) => {
                        onClose();
                        props.onPageSelected(page);
                      }} 
            />
          </DrawerContent>
        </Drawer>
      </Flex>
    </Box>
  )
}
