import { Box, Heading, Stack, useBreakpointValue, useColorModeValue, Text } from "@chakra-ui/react"

interface IStatProps {
    label: string
    value: string
  }
  export const Stat = (props: IStatProps) => {
    const { label, value, ...boxProps } = props
    return (
      <Box
        px={{ base: '4', md: '6' }}
        py={{ base: '5', md: '6' }}
        bg="bg-surface"
        borderRadius="lg"
        boxShadow={useColorModeValue('sm', 'sm-dark')}
        {...boxProps}
      >
        <Stack>
          <Text fontSize="sm" color="muted">
            {label}
          </Text>
          <Heading size={useBreakpointValue({ base: 'sm', md: 'md' })}>{value}</Heading>
        </Stack>
      </Box>
    )
  }