import { Button, ButtonGroup, ButtonProps, Container, useColorModeValue, BoxProps, Divider, Textarea ,Flex, Heading, HStack, Icon, IconButton, useColorModeValue as mode, IconButtonProps, Input, Stack, Text, useEditableControls, VStack, StackDivider, InputGroup, InputLeftElement, useBreakpointValue, Badge, Table, Tbody, Td, Th, Thead, Tr, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, ListIcon, List, ListItem, Tag, TagLeftIcon, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger } from '@chakra-ui/react'
import * as React from 'react'
import { IDeleteConfirmationPopoverProps } from './IDeleteConfirmationPopover';

export const DeleteConfirmationPopover = (props: IDeleteConfirmationPopoverProps) => {
    return (
        <Popover placement={props.placement}>
            { ({ onClose }) => (
                <>
                    <PopoverTrigger>
                        {props.trigger}
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverHeader fontSize="sm" fontWeight='semibold'>{props.header}</PopoverHeader>
                        <PopoverArrow />
                        <PopoverBody fontSize="sm" style={{ whiteSpace: 'normal' }}>{props.body}</PopoverBody>
                        <PopoverFooter display='flex' justifyContent='flex-end'>
                            <ButtonGroup size='sm'>
                                <Button variant='outline' onClick={onClose}>Abbrechen</Button>
                                <Button colorScheme='red' onClick={props.onConfirm}>Bestätigen</Button>
                            </ButtonGroup>
                        </PopoverFooter>
                    </PopoverContent>
                </>
            )}
        </Popover>
    );
}