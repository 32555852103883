import { As, Box, Button, ButtonGroup, ButtonProps, Container, useColorModeValue, BoxProps, Divider, Textarea ,Flex, Heading, useColorModeValue as mode, HStack, Icon, IconButton, IconButtonProps, Input, Stack, Text, useEditableControls, VStack, StackDivider, InputGroup, InputLeftElement, useBreakpointValue, Badge, Table, Tbody, Td, Th, Thead, Tr, ListItem, List, Square, UnorderedList, EditableInput, Editable, EditablePreview, ListIcon, useEditableState, SimpleGrid, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, Tag, TagLeftIcon, DrawerCloseButton, Portal, AccordionPanel, AccordionItem, Accordion, AccordionButton, AccordionIcon } from '@chakra-ui/react'
import * as React from 'react'
import { useState } from 'react';
import { FiEdit2, FiMail, FiCheck, FiX, FiSquare, FiEyeOff, FiRefreshCw, FiSettings } from 'react-icons/fi';
import API from '../../../../api/API';
import { IApiBauKGProtocol } from '../../../../api/models/IApiBauKGProtocol';
import { RadioIconButtonGroup, RadioIconButton } from '../../../cui-pro/RadioIconButtonGroup/RadioIconButtonGroup';
import { ShellContentContainer } from '../../../cui-pro/ShellWithSidebar/ShellContentContainer';
import { StepsWithAccent } from '../../../cui-pro/StepsWithAccent/StepsWithAccent';
import { Step } from '../../../cui-pro/StepsWithCircles/Step';
import { useStep } from '../../../cui-pro/StepsWithCircles/useStep';
import { StepsWithCirclesAndText } from '../../../cui-pro/StepsWithCirclesAndText/StepsWithCirclesAndText';
import { IEditProtocolDrawerProps } from './IEditProtocolDrawer';

interface IChecklistEntryProps {
    entry: string;
    value: boolean | null;
    index: number;
    onChange(value: boolean | null): void;
}

const ChecklistEntry = (props: IChecklistEntryProps ) => {

    return (
        <Container maxW="lg" px={'0'} mt={props.index > 0 ? 6 : 0} key={`entry-${props.entry}`}>
            <h6>
                <Box flex='1' textAlign='left' fontWeight='normal' fontSize='14px'>{props.entry}</Box>    
            </h6>
            <RadioIconButtonGroup key={props.entry} defaultValue={`${props.value ?? 'null'}`} size={'sm'} display="flex" mt={2} 
                                  onChange={e => {
                                      switch(e) {
                                          case "false": { props.onChange(false); break; }
                                          case "true": { props.onChange(true); break; }
                                          case "null": { props.onChange(null); break; }
                                      }
                                  }}
            >
                <RadioIconButton
                    value="false"
                    aria-label="Align left"
                    icon={<FiX fontSize="1.125rem" />}
                    grow={true}
                    _checked={{
                        bg: 'red.200'
                    }}
                />
                <RadioIconButton
                    value="true"
                    aria-label="Align center"
                    icon={<FiCheck fontSize="1.125rem" />}
                    grow={true}
                    _checked={{
                        bg: 'green.200'
                    }}
                />
                <RadioIconButton
                    value="null"
                    aria-label="Align right"
                    icon={<FiEyeOff fontSize="1.125rem" />}
                    grow={true}
                />
            </RadioIconButtonGroup>
        </Container>
    );
}

export interface IEditProtocolChecklistProps {
    protocol: IApiBauKGProtocol;
    template: { [key: string]: string[] };
    setChecklist: React.Dispatch<React.SetStateAction<{ [key: string]: { [key: string]: boolean | null } }>>
}

export const EditProtocolChecklist = ({ protocol, template, setChecklist }: IEditProtocolChecklistProps ) => {

    const topics = Object.getOwnPropertyNames(protocol.checklist);

    return (
        <Accordion allowMultiple={true} defaultIndex={topics.map((_, i) => i)} overflow="auto">
            {topics.map((topic) => (
                <AccordionItem key={`topic-${topic}`}>
                    <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='left' fontWeight='semibold' fontSize='16px'>{topic}</Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel>
                        {Object.getOwnPropertyNames(protocol.checklist[topic]).map((entry, index) => (
                            <ChecklistEntry index={index} entry={entry} value={protocol.checklist[topic][entry]} 
                                            onChange={(value) => {
                                                setChecklist(oldChecklist => ({
                                                    ...oldChecklist,
                                                    [topic]: {
                                                        ...oldChecklist[topic],
                                                        [entry]: value
                                                    }
                                                }));
                                            }}
                            />
                        ))} 
                    </AccordionPanel>
                </AccordionItem>
            ))}
            <AccordionItem key={`reload-template`}>
                <AccordionButton>
                    <Box flex='1' textAlign='left' fontWeight='normal' fontSize='16px'>[Optionen]</Box>
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                    <Button leftIcon={<FiRefreshCw />} 
                            variant="outline" 
                            size="lg"
                            w="100%"
                            mt="1"
                            color='red' 
                            onClick={() => {
                                var data : {
                                    [key: string]: {
                                        [key: string]: boolean | null;
                                    };
                                } = {};
                                for(var topic of Object.getOwnPropertyNames(template)) {
                                    data[topic] = {};
                                    for(var item of template[topic]){
                                        data[topic][item] = null;
                                    }
                                }
                                setChecklist(data);
                            }}
                    >
                        Mit aktueller Vorlage überschreiben
                    </Button>
                </AccordionPanel>
            </AccordionItem>
            
        </Accordion>
    )
}